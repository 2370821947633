export const modulesList = [
  {
    id: "0",
    title: "mainSystem",
    displayTitle: "mainSystem",
    visible: true,
    basePath: "/",
    hasSubSystem: true,
    subSystem: [
      {
        id: "01",
        title: "Dashboard",
        displayTitle: "Dashboard",
        path: "/dashboard",
        visible: true,
        hasMenus: false,
        hasDetail: false,
        menus: [],
      },
      {
        id: "02",
        title: "EndPoint",
        displayTitle: "EndPoint",
        path: "/endpoint",
        visible: true,
        hasMenus: false,
        hasDetail: true,
        detailUrl: "/endpoint/detail",
        menus: [],
      },
      {
        id: "03",
        title: "Wallet",
        displayTitle: "Wallet",
        path: "/wallet",
        visible: true,
        hasMenus: false,
        hasDetail: false,
        menus: [],
      },
      {
        id: "04",
        title: "Payment",
        displayTitle: "Payment",
        path: "/payment",
        visible: true,
        hasMenus: true,
        menus: [
          {
            id: "041",
            title: "purchase",
            displayTitle: "purchase",
            path: "/payment/purchase",
            visible: true,
          },
          {
            id: "042",
            title: "Payment History",
            displayTitle: "PaymentHistory",
            path: "/payment/paymentHistory",
            visible: true,
          }]
      },
    ],
  },
];