import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      fill="none"
      viewBox="0 0 24 16"
    >
      <path
        fill="#1F499F"
        d="M.293 8.707a1 1 0 010-1.414L6.657.929A1 1 0 018.07 2.343L2.414 8l5.657 5.657a1 1 0 11-1.414 1.414L.293 8.707zM24 9H1V7h23v2z"
      ></path>
    </svg>
  );
}

export default Icon;
