import types from './Types';
import { theme } from '../../utils/theme';
const initState = {
  lang: 'en',
  direction: 'ltr',
  theme,
  userMenu: false,
  pageInfo: null
};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.USER_MENU:
      return {
        ...state,
        userMenu: action.data,
      };
    case types.SET_PAGE_INFO:
      return {
        ...state,
        pageInfo: action.data,
      };
    default:
      return state;
  }
}