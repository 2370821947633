import types from "./Types";
import RecordController from "src/controllers/RecordController";
import {prepareSelectData} from "src/utils/index"

const initState = {
  showWalletDetailModal: false,
  WalletList: [],
  walletLoading: true,
  takewallet: 1000,
  skipWallet: 0,
  hasMore: false,
  count: 0,
  mode: "add",
  showGenerateNewApiKeyModal: false,
  confirmStep: 0,
  walletDetail: null,
  waasUrl: "",
  parentWallet: [],
  parentWalletsOptions: [],

};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.SET_SHOW_WALLET_DETAIL_MODAL:
      return {
        ...state,
        showWalletDetailModal: action.data.visible,
        mode: action.data.mode,
      };
    case types.GET_WALLETS_LIST:
      return {
        ...state,
        walletLoading: true,

      };
    case types.GET_WALLETS_LIST_SUCCESS:
      let newWalletList = [...state.WalletList];
      newWalletList = [...action.data.data.wallets.items];
      return {
        ...state,
        walletLoading: false,
        WalletList: newWalletList,
        skipWallet: action.data.skip + state.takewallet,
        count: action.data.data.wallets.totalItemsCount,
        hasMore: action.data.skip + state.takewallet < action.data.data.wallets.totalItemsCount ? true : false,
      };
    case types.GET_WALLET_BY_ID_SUCCESS:
      return {
        ...state,
        walletDetail: action.data
      }
    case types.ADD_WALLET_SUCCESS:
      return {
        ...state,
        WalletList: RecordController.add(
          state.WalletList,
          action.data
        ),
        showWalletDetailModal: false,
        mode: "add",
      };
    case types.SET_SHOW_GENERATE_API_KEY_MODAL:
      return {
        ...state,
        showGenerateNewApiKeyModal: action.data.visible
      };
    case types.GET_PARENT_WALLETS_LIST_SUCCESS:
      return {
        ...state,
        parentWallet: action.data.data,
        parentWalletsOptions: prepareSelectData("parenrWallet", action.data.data)
      };
    default:
      return state;
  }
}