import React from "react";
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import ColumnChart from "src/charts/ColumnChart";
import VirtualizedSelect from 'react-virtualized-select';
import { Grid } from "@mui/material";
import subSystemMenuCrtl from "../dashboard.controllers"
import { withTranslation } from "react-i18next";

const Wass = ({t}) => {
    const { wassData,
        yearsForWaas,
      } = subSystemMenuCrtl();

    return (
        <ChartContainer>
            <TitleContainer paddingtop="20px">{t("Waas daily requests")}</TitleContainer>
            <Grid container>
            </Grid>
            <ColumnChart series={wassData} years={yearsForWaas} height="400" noData={t("No data to display")}/>
        </ChartContainer>
    )
}
export default withTranslation()(Wass)