export const loginCode = [
    { code: "001", titleKey: "loginError001", title: "The entered email and password do not match." },
    { code: "002", titleKey: "loginError002", title: "The number of login attempts is too high, please try again later." },
    { code: "003", titleKey: "loginError003", title: "You must accept the agreement to register." },
    { code: "004", titleKey: "loginError004", title: "Registration successful. A confirmation link has been sent to your email." },
    { code: "005", titleKey: "loginError005", title: "The entered email is already registered." },
    { code: "006", titleKey: "loginError006", title: "The password confirmation is incorrect." },
    { code: "007", titleKey: "loginError007", title: "The entered password is not secure enough. It must be at least eight characters long and include a digit, a lowercase letter, an uppercase letter, and a symbol." },
    { code: "008", titleKey: "loginError008", title: "If the email is correct, a password recovery link will be sent to you." },
    { code: "009", titleKey: "loginError009", title: "The password confirmation is incorrect." },
    { code: "010", titleKey: "loginError010", title: "The entered password is not secure enough. It must be at least eight characters long and include a digit, a lowercase letter, an uppercase letter, and a symbol." },
    { code: "011", titleKey: "loginError011", title: "The password was changed successfully." },
    { code: "012", titleKey: "loginError012", title: "" },
    { code: "013", titleKey: "loginError013", title: "Profile saved successfully." },
    { code: "014", titleKey: "loginError014", title: "You cannot view the information for this endpoint." },
    { code: "015", titleKey: "loginError015", title: "The wallet name is duplicate." },
    { code: "016", titleKey: "loginError016", title: "The entered username or email is incorrect." },
    { code: "017", titleKey: "loginError017", title: "The entered password is incorrect." },
    { code: "018", titleKey: "loginError018", title: "The time range is incorrect." },
    { code: "019", titleKey: "loginError019", title: "The user account is inactive." }
  ];