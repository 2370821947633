import React, { useState, useEffect } from "react";
import loading from "src/assets/images/spinner.gif";
import success from "src/assets/images/sucess-payment.png";
import error from "src/assets/images/error-payment.png";
import { PurchaseWrapper, PurchaseImageContainer, ActionsContainer,TextContainer } from "../styles";
import { CardContainer } from "src/shared/generalStyles";
import { Button, Box } from "@mui/material";
import Storage from "src/storage";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const Purchase = ({  data }) => {
    let location = useLocation();
    const { t } = useTranslation();
    const direction = useSelector((state) => state.App.direction);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentStatusImage, setPaymentStatusImage] = useState(loading);

    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');

    useEffect(()=>{
        setTimeout((e)=>{
            
            let result = Number(status) === 1 ? "success" : "error"; 
            setPaymentStatus(result);
        },2000)
    },[setPaymentStatusImage, setPaymentStatus]);
    const returnHome = (e) =>{
        Storage.clearPaymentInfo();
        window.location.href = "/"
    }

    const tryAgain = (e) =>{
        Storage.clearPaymentInfo();
        window.location.href = "/payment/purchase"
    }

    return (
        <CardContainer>
        <PurchaseWrapper>
            <Box id="purchase-container">
                <PurchaseImageContainer src={paymentStatus.length === 0 ? loading : (paymentStatus === "success" ?  success : error)} />
                <TextContainer color={paymentStatus === "success" ? "green" : "red"}
                               style={{"display": paymentStatus.length > 0 ? "flex" : "none", fontSize:"2rem"}}>
                    {
                        paymentStatus === "success" ?
                        t("YourPaymentWasSuccessful") :
                        t('YourPaymentWasNotSuccessful')
                    }
                </TextContainer>

                <ActionsContainer  justify={direction === "rtl" ? "start" : "end"} style={{"display": paymentStatus.length > 0 ? "flex" : "none"}}>
                    <Button className="btn "  onClick={paymentStatus === "success" ? returnHome : tryAgain}>
                        {paymentStatus === "success" ? t("dashboard") : t("tryAgain")}</Button>
                </ActionsContainer>
            </Box>
        </PurchaseWrapper>
        </CardContainer>
    )
}
export default Purchase;