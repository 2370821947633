import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DashboardActions from "src/redux/dashboard/Actions";
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const direction = useSelector((state) => state.App.direction);
    const DashboardState = useSelector((state) => state.Dashboard);
    const { endpointsListOptions, statisticsData, statisticsEndpointList, statisticsWaas, statisticsUsedEndpoint } = DashboardState;

    const [selectedEndPoint, setselectedEndPoint] = useState(endpointsListOptions[0]);

    const [DonutChartData, setDonutChartData] = useState([]);
    const [LineData, setLineData] = useState([]);
    const [wassData, setWassData] = useState([]);
    const [years, setyears] = useState([]);
    const [yearsForWaas, setyearsForWaas] = useState([]);


    useEffect(() => {
        if ((selectedEndPoint === null || selectedEndPoint === undefined)) {
            setselectedEndPoint(endpointsListOptions[0]);
        }
    }, [endpointsListOptions]);

    useEffect(() => {
        renderDonutChartData();
    }, [statisticsData]);
    ///////////////////////////endpoint
    useEffect(() => {
        renderColumnChartData(statisticsEndpointList, "endpoint");
    }, [statisticsEndpointList]);

    useEffect(() => {
        renderColumnChartData(statisticsEndpointList, "endpoint");
    }, [selectedEndPoint])
    //////////////////////////////wass
    useEffect(() => {
        renderColumnChartData(statisticsWaas, "waas");
    }, [statisticsWaas]);

    ///////////////////////////////////////
    const renderDonutChartData = () => {
        if (statisticsData !== null) {
            let failed = statisticsData.total - statisticsData.successful;
            if (failed === 0 && statisticsData.successful === 0) {
                setDonutChartData([]);
            }
            else {
                setDonutChartData([{
                    innerSize: '50%',
                    data: [
                        { name: t('failed'), y: failed },
                        { name: t('successful'), y: statisticsData.successful }
                    ]
                }])
            }

        }
    }

    const renderColumnChartData = (data, type) => {
        
        let successfulList = [];
        let failedList = [];
        let dateList = [];
        let result = [];

        if (data.length > 0) {
            data.map((each, index) => {
                successfulList.push(each.successful);
                failedList.push(each.failed);
                dateList.push(moment(each.date).format("YYYY-MM-DD"));
            })
        }

        if (failedList.length === 0 && successfulList.length === 0) {
            result = [];
        }
        else {
            result = [
                {
                    name: t('failed'),
                    data: failedList
                },
                {
                    name: t('successful'),
                    data: successfulList
                }
            ]
        }
        if (type === "waas") {
            setWassData(result);
            setyearsForWaas(dateList);
           
        }
        else if (type === "endpoint") {
            setLineData(result);
            setyears(dateList);
        }
    }

    const sendStatisticsEndpointHandler = (selected) => {
        dispatch(DashboardActions.sendStatisticsEndpoint({ id: selected.value }))
    }
    const sendStatisticsWaasHandler = (selected) => {
        dispatch(DashboardActions.sendStatisticsWaas({ id: selected.value }))
    }

    return {
        DonutChartData,
        LineData,
        years,
        yearsForWaas,
        selectedEndPoint,
        endpointsListOptions,
        setselectedEndPoint,
        statisticsUsedEndpoint,
        sendStatisticsEndpointHandler,
        wassData,
        sendStatisticsWaasHandler,
        direction
    }
}
