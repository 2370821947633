import React from "react";
import { LoginRegisterFormWrapper } from "./styles";
import LoginForm from "../LoginForm";
import RegiserForm from "../RegisterForm";
import Pic from "src/assets/images/loginRegister.png";


const LoginRegisterContent = ({ mode, setMode }) => {

    return (
        <LoginRegisterFormWrapper  mode={mode}  src={Pic}>
            {mode === "login" ? <LoginForm setMode={setMode} /> : <RegiserForm setMode={setMode}/>}
        </LoginRegisterFormWrapper>
    )
}
export default LoginRegisterContent;