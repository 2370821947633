import styled from "styled-components";
import Column from "src/kit/Column";
import Row from "src/kit/Row";

export const FormRow = styled(Column)`
   width : 100%;
   padding:0 10px;
   padding-bottom :0;
   box-sizing : border-box;
   .text-alert{
      color : red;
      font-size: 10px;
      width: 100%;
      height :20px;
   }
`;
export const FilterContainer = styled(Row)`
   width : 100%;
   padding-bottom :0;
   box-sizing : border-box;
   .filter-box{
      width : 100%;
      justify-content: flex-start;
   }
   .btn-filter{
      font-family: ${(props) => (props.theme.fontDefault)};
      padding:4px;
      background-color : ${props => props.theme.primaryDark};
      color:${props => props.theme.white}; 
      width : 100px;
      border:none;
      height :29px;
      cursor : pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius :5px;
    }
    .rmdp-container {
      width: 100%;
    }
    .rmdp-input{
      height:32px;
      width: 100%;
    }
`;

export const FormContainer = styled(Column)`
  padding : 10px 20px;
  border-top :1px solid #ccc;
  form {
   width: 100%;
  }

`;
