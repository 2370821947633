import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#ABAFB3"
        d="M12.314 17.555c0 .413.006.765 0 1.116-.015.649-.345.991-.991 1.018-.355.015-.71.002-1.128.002 0 .416.01.785-.002 1.154-.021.61-.336.916-.951.93-.355.01-.71 0-1.141 0 0 .372.004.708 0 1.042-.015.747-.338 1.073-1.074 1.08-.324.005-.649 0-1.045 0 0 .393.006.762 0 1.128-.013.611-.32.955-.922.964-1.383.016-2.766.016-4.148 0-.584-.009-.9-.343-.901-.929-.01-1.726-.023-3.45.014-5.175.007-.281.205-.617.413-.83a989.948 989.948 0 016.933-6.942c.25-.249.319-.463.24-.808C6.368 6.001 10.103.711 15.53.07c5.936-.701 10.885 4.048 10.441 10.015-.327 4.401-3.879 8.026-8.257 8.47a9.52 9.52 0 01-5.032-.832c-.1-.046-.2-.09-.37-.17v.003zM4.21 24.192c0-.363-.004-.701 0-1.04.008-.698.315-1.007 1.01-1.026.699-.02 1.063-.386 1.092-1.101.027-.691.347-1.006 1.033-1.019.35-.006.7 0 1.074 0 0-.413-.006-.753 0-1.093.014-.655.331-.983.993-1.01.354-.015.709-.002 1.128-.002 0-.438-.004-.826 0-1.212.004-.449.24-.81.659-.845.73-.063 1.454-.121 2.188.223 2.738 1.277 5.406 1.052 7.82-.754 2.46-1.84 3.409-4.398 2.87-7.405-.765-4.28-4.972-6.952-9.172-5.934a7.508 7.508 0 00-5.43 9.48c.174.588.064 1.01-.372 1.44-2.05 2.021-4.032 4.114-6.134 6.077-.922.862-1.376 1.74-1.218 2.997.092.73.017 1.481.017 2.226h2.438l.004-.002z"
      ></path>
      <path
        fill="#ABAFB3"
        d="M21.79 7.222a2.995 2.995 0 01-2.992 2.97 2.986 2.986 0 01-2.984-3.022 2.996 2.996 0 012.988-2.974c1.652 0 3 1.365 2.989 3.028v-.002zM18.8 8.4c.665 0 1.212-.534 1.22-1.196a1.241 1.241 0 00-1.197-1.229 1.22 1.22 0 00-1.235 1.231c.007.67.538 1.194 1.214 1.194H18.8z"
      ></path>
    </svg>
  );
}

export default Icon;
