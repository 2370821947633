import React,{useState} from "react";
import LoginRegisterContent from 'src/views/LoginRegister/LoginRegisterContent';


const Login = () => {
   const [mode, setMode] = useState('login');
   return (
      <LoginRegisterContent mode={mode} setMode={setMode}/>

   )
}
export default Login