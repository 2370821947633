import React from "react";
import { Modal, Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import CloseIcon from "src/assets/icons/CloseIcon";
import { FormContainer } from "../styles";
import { useSelector } from "react-redux";
import { VerifyValidationAddEndpoint } from "src/validation/schemas/verifyValidationAddEndpoint";
import VirtualizedSelect from 'react-virtualized-select';
import { useTranslation } from 'react-i18next';
import Button from "src/kit/Button";

const EndpointDetailModal = (props) => {
    const { show,
        onclose,
        title,
        fields,
        onConfirm,
    } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const direction = useSelector((state) => state.App.direction);
    const endpointState = useSelector((state) => state.Endpoint);
    const { endpointsOptions } = endpointState;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? "90vw" : "60vw",
        height: isSmallScreen ? "auto" : "auto",
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
        direction: direction
    };

    return (
        <Formik
            initialValues={{ ...fields }}
            isInitialValid={false}
            validationSchema={VerifyValidationAddEndpoint}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                onConfirm(values, action);
            }}
        >
            {(formik) => (

                <Modal
                    open={show}
                    onClose={() => onclose(formik)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <Grid item container xs={12} id="modal_title" p={2}>
                            <Grid item xs={11} display="flex" justifyContent="start">
                                {title}
                            </Grid>
                            <Grid item xs={1} onClick={() => onclose(formik)} className="clickable" display="flex" justifyContent="end">
                                <CloseIcon />
                            </Grid>
                        </Grid>
                        <FormContainer direction={direction}>
                            <form className="py-5" onSubmit={formik.handleSubmit}>
                                <Grid container display="flex" justifyContent="end">

                                    <Grid item p={1} xs={12} md={6}>
                                        <Box className="form_lable">{t('EndPoint')}</Box>
                                        <VirtualizedSelect
                                            value={formik.values.endpointId}
                                            noResultsText={t("No data to display")}
                                            name="endpointId"
                                            id="endpointId"
                                            placeholder={t("select")}
                                            onChange={(selected) => {
                                                FormController.onSelect(selected, "endpointId", formik, null, true);
                                            }}
                                            maxHeight={150}
                                            options={endpointsOptions}
                                            multi={false}
                                            className={
                                                "custom_input" + (formik.errors.endpointId && formik.touched.endpointId ? " warning-border" : "")
                                            }
                                            backspaceToRemoveMessage=""
                                        />
                                        {/* {formik.errors.endpointId && formik.touched.endpointId ? (
                                            <div className="text-alert">{formik.errors.endpointId}</div>
                                        ) : <div className="text-alert"></div>} */}
                                    </Grid>
                                    <Grid item p={1} xs={12} md={6}>
                                        <Box className="form_lable">{t('name')}</Box>
                                        <FormInput
                                            value={formik.values.title}
                                            name="title"
                                            id="title"
                                            placeholder={t('name')}
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.title && formik.touched.title ? " warning-border" : "")
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} p={1} md={4} >
                                        <Button
                                            onClick={formik.handleSubmit}
                                            text={t("save")}
                                        >
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContainer>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default EndpointDetailModal