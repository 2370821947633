import React , { useEffect }from "react";
import PurchaseView from "src/views/payment/purchase";
import MainLayout from "src/shared/MainLayout";
import { useDispatch } from "react-redux";
import PaymentActions from "src/redux/Payment/Actions";


const Purchase = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(PaymentActions.getPriceList());
    },[])
    return (
        <MainLayout>
            <PurchaseView />
        </MainLayout>
    )
}
export default Purchase