import React from "react";
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import DonutChart from "src/charts/DonutChart";
import subSystemMenuCrtl from "../dashboard.controllers"
import { withTranslation } from "react-i18next";

const RequestStatus = ({t}) => {
  const { DonutChartData } = subSystemMenuCrtl();

    return (
            <ChartContainer>
                <TitleContainer paddingtop="20px">{t("Requests status")}</TitleContainer>
                <DonutChart height="400" series={DonutChartData} noData={t("No data to display")} />
            </ChartContainer>
    )

}
export default withTranslation()(RequestStatus);   