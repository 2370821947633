import { all } from 'redux-saga/effects';
import LoginSagas from './Login/Sagas';
import EndpointSagas from './Endpoint/Sagas';
import UserSagas from './User/Sagas';
import DashboardSagas from './dashboard/Sagas';
import WalletSagas from './wallet/Sagas';
import PaymentSagas from './Payment/Sagas';


export default function* sagas(getState) {
  yield all([
    LoginSagas(),
    EndpointSagas(),
    UserSagas(),
    DashboardSagas(),
    WalletSagas(),
    PaymentSagas()
  ]);
}