import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="27"
      fill="none"
      viewBox="0 0 23 27"
    >
      <path
        fill="#AAA"
        d="M12.07 0c.519.117 1.053.191 1.556.36 2.877.96 4.782 3.8 4.552 6.747-.247 3.157-2.514 5.664-5.584 6.178-3.788.634-7.356-2.083-7.742-5.896A6.679 6.679 0 0110.755.044c.06-.007.116-.029.173-.044h1.141zm-.58 12.344a5.65 5.65 0 005.661-5.648c0-3.11-2.529-5.648-5.632-5.655-3.124-.007-5.667 2.52-5.672 5.64a5.65 5.65 0 005.644 5.663z"
      ></path>
      <path
        fill="#AAA"
        d="M11.496 26.551H3.15c-1.831 0-3.132-1.302-3.147-3.129-.012-1.41.015-2.814.396-4.189.585-2.115 1.658-3.936 3.261-5.436 1.106-1.035 2.471-1.143 3.786-.383 2.688 1.553 5.4 1.558 8.091.009 1.347-.776 2.704-.674 3.825.397 2.365 2.262 3.564 5.043 3.63 8.312.01.57.023 1.143-.028 1.709-.138 1.543-1.428 2.704-2.99 2.707-2.825.006-5.65.002-8.476.002v.001zm-.013-1.037h2.177c2.092 0 4.183.003 6.274-.002 1.132-.002 1.966-.816 2.002-1.946.013-.423.006-.846.003-1.27-.017-3.03-1.115-5.601-3.29-7.71-.77-.745-1.656-.842-2.572-.276-1.396.863-2.919 1.278-4.552 1.283-1.669.006-3.226-.411-4.65-1.3-.817-.51-1.723-.453-2.394.159-1.534 1.398-2.559 3.106-3.099 5.11-.337 1.25-.358 2.525-.348 3.805.01 1.327.825 2.148 2.153 2.148h8.296v-.001z"
      ></path>
    </svg>
  );
}

export default Icon;
