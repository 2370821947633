import React from "react";

function Icon({pathFill}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      fill="none"
      viewBox="0 0 26 24"
    >
      <path
        fill={pathFill}
        stroke={pathFill}
        strokeWidth="0.2"
        d="M9.087 12.86h15.13a.883.883 0 000-1.765H9.088a.883.883 0 000 1.765z"
      ></path>
      <path
        fill={pathFill}
        stroke={pathFill}
        strokeWidth="0.2"
        d="M6.463 12.602h0l4.174 4.174.07-.07-.07.07h0a.884.884 0 001.248.001h0a.883.883 0 000-1.248l-3.55-3.55 3.55-3.55a.883.883 0 000-1.249h0a.883.883 0 00-1.248 0l-4.174 4.174a.883.883 0 000 1.248z"
      ></path>
      <path
        fill={pathFill}
        stroke={pathFill}
        strokeWidth="0.2"
        d="M22.756 8.888h.001a.884.884 0 00.497-1.145C21.498 3.283 17.268.4 12.478.4 6.094.4.9 5.594.9 11.978c0 6.384 5.194 11.579 11.578 11.579 4.79 0 9.02-2.883 10.777-7.342a.884.884 0 00-.498-1.145h0a.883.883 0 00-1.145.499 9.763 9.763 0 01-9.134 6.222c-5.41 0-9.813-4.402-9.813-9.813 0-5.41 4.403-9.813 9.813-9.813a9.762 9.762 0 019.134 6.224h0a.884.884 0 001.144.499z"
      ></path>
    </svg>
  );
}
Icon.defaultProps = {
  pathFill: "#e39b10",
}
export default Icon;
