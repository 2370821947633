import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import { Formik } from "formik";
import Button from "src/kit/Button";
import CloseIcon from "src/assets/icons/CloseIcon";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const SendEmailModal = ({
    open,
    title,
    onClose,
    onConfirm,
    btnText
}) => {
    const { t } = useTranslation();
    const direction = useSelector((state) => state.App.direction);
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        direction :direction
    };
    
    return (
        <Modal
            open={open}
            onClose={() => { onClose(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>
                {title &&
                <Grid item container xs={12} p={2} sx={{ borderBottom: 1 ,borderColor: 'grey.500'}}>
                    <Grid item xs={11} display="flex" justifyContent="start">
                        {title}
                    </Grid>
                    <Grid item xs={1} onClick={() => onClose(false)} className="clickable">
                        <CloseIcon />
                    </Grid>
                </Grid>}
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={(values, action) => {
                        onConfirm(values)
                    }}
                >
                    {(formik) => {
                        return (
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container p={2}>
                                    <Grid item xs={12} p={1}>
                                       {t("Upon_receiving_a_new_api_key_the_previously_received_api_key_expires")}
                                    </Grid>
                            
                                    <Grid item xs={12} p={1}>
                                        <Button
                                            onClick={() => formik.handleSubmit()}
                                            text={btnText}
                                        >
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Modal>
    )
}
export default SendEmailModal;