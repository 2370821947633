import React from "react";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { useDispatch, useSelector } from "react-redux";
import PaymentActions from "src/redux/Payment/Actions";
import { Grid } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa";
import { useTranslation } from 'react-i18next';


const FilterMenu = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { filterState, setFilterState } = props;
    const { startDateFrom,
        startDateTo } = filterState;
    const PaymentState = useSelector((state) => state.Payment);
    const { takePaymentHistory } = PaymentState;
    const direction = useSelector((state) => state.App.direction);
    const handleSearchContent = (formik) => {

        setFilterState({
            ...filterState,
            startDateFrom: formik.values.startDateFrom,
            startDateTo: formik.values.startDateTo,
        });
        dispatch(
            PaymentActions.getPaymentList({
                startDateFrom: formik.values.startDateFrom,
                startDateTo: formik.values.startDateTo,
                offset: 0,
                limit: takePaymentHistory,
            })
        );
    };
    return (
        <Formik
            initialValues={
                {
                    startDateFrom,
                    startDateTo
                }
            }
            isInitialValid={false}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                handleSearchContent(values, action);
            }}

        >
            {(formik) => (

                <form className="py-5" onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid item xs={12} md={3} pl={2}>
                            <DatePicker
                                value={formik.values.startDateFrom}
                                calendar={direction === "rtl" && persian}
                                locale={direction === "rtl" && persian_fa}
                                className={
                                    "input_file" + (formik.errors.startDateFrom && formik.touched.startDateFrom ? " warning-border" : "")
                                }
                                placeholder={t("startDateFrom")}
                                name="startDateFrom"
                                onChange={(e) => {
                                    FormController.onChangeDate(e, formik, "startDateFrom", null);
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} md={3} px={2}>
                            <DatePicker
                                value={formik.values.startDateTo}
                                calendar={direction === "rtl" && persian}
                                locale={direction === "rtl" && persian_fa}
                                className={
                                    "input_file" + (formik.errors.startDateTo && formik.touched.startDateTo ? " warning-border" : "")
                                }
                                placeholder= {t("startDateTo")}
                                name="startDateTo"
                                onChange={(e) => {
                                    FormController.onChangeDate(e, formik, "startDateTo", null);

                                }}
                            />

                        </Grid>
                        <Grid item xs={12} md={3} px={2} display="flex">
                            <span className="btn-filter" onClick={() => handleSearchContent(formik)}>
                                {t("search")}
                            </span>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}

export default FilterMenu;
