import types from './Types';
import DelegateAction from '../ActionDelegator';

const WalletActions = {
  setShowWalletDetailModal: DelegateAction(types.SET_SHOW_WALLET_DETAIL_MODAL),
  getWallet : DelegateAction(types.GET_WALLETS_LIST),
  addWallet : DelegateAction(types.ADD_WALLET),
  getWalletById : DelegateAction(types.GET_WALLET_BY_ID),
  setShowGenerateApiKeyModal : DelegateAction(types.SET_SHOW_GENERATE_API_KEY_MODAL),
  getParentWallet : DelegateAction(types.GET_PARENT_WALLETS_LIST),

};

export default WalletActions;