import React from "react";
import styled from "styled-components";
import Col from "./Column";

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: auto;
`;
export const FormGroup = styled(Col)`
  flex-direction: column;
  height: auto;
  width: 45%;
  height: 32px;
  border-radius: 10px;
  background-color: #dddddd;
  outline: none;
  position: relative;
  margin : 8px 0px 8px 0px;
  :focus {
    outline: none;
  }
  @media screen and (max-width: 768px) {
    width: 100px;
  }
`;
export const StyledTextArea = styled.textarea`
 flex-direction: column;
  height: auto;
  width: 100%;
  height: 114px;
  border-radius: 5px;
  border: 1px solid #CFD8DE;
  background-color: white;
  outline: none;
  position: relative;
  margin : 8px 0px 8px 0px;
  resize : none;
  padding : 10px;
  :focus {
    outline: none;
  }
  box-sizing: border-box;
font-family: ${(props) => (props.theme.fontDefault)};

`
export const FormInput = styled.input`
  padding: 0 10px;
  height: 45px;
  min-height: 35px;
  width: ${(props) => props.width ? props.width+"%" : "100%"}; 
  border: 1px solid #DCDCDC;
  outline: none;
  background-color: #fff;
  color: ${(props) => props.theme.text};
  font-size: ${(props) => props.theme.fontSizeSm};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: ${(props) => props.readOnly === true && "no-drop"};
  direction: ${(props) => props.direction ? props.direction : "rtl"}; 
  :focus {
    outline: none;
  }
  ::placeholder {
    direction: ltr;
    color : ${props => props.theme.text};
    padding-right : 10px;
  }
  box-sizing: border-box;
font-family: ${(props) => (props.theme.fontDefault)};

`;
export const FormLable = styled.label`
  height: 20px;
  background-color: white;
  display: ${(props) => props.show !== true && "none"};
  position: absolute;
  top: -15px;
  right: 10px;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  justify-content: flex-start;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /* animation: fade 300ms ease 10ms 1 forwards ; */
`;
export const FormError = styled(Col)`
  color: red;
  font-size: 12px;
  justify-content: flex-start;
  width: 100%;
  min-height: 0px;
  padding: 5px 0px 5px 0px;
  height: auto;
`;
export const StyledInput = (props) => {
    const {lable} = props;
    return (
        <FormGroup>
        <FormLable show={props.focus} >{lable}</FormLable>
        <FormInput  {...props} />
        </FormGroup>
    );
};