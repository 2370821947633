export default {
  GetEndpointsList : "",
  AddEndpoint : "add",
  GetEndpointById : "details",
  GetDetailsStatusBasedChart : "details-status-based-chart",
  GetBlockchainsList : "blockchains",
  GetEndpoints : "endpoints",
  GetNetworksList : "networks",
  GenerateApiKey : "generate-apikey",
  DownloadDocument : "download-document",
  GetDetailsCuBasedChart : "details-cu-based-chart",

};