import Avatar from 'src/assets/icons/AvatarIcon';
import LogoutIcon from 'src/assets/icons/LogoutIcon';
import HomeIcon from 'src/assets/icons/KeyIcon';

export const UserMenuConfig = [
  {
    id: 0,
    title: 'profile',
    icon: Avatar,
  },
  {
    id: 2,
    title: 'changePassword',
    icon: HomeIcon,
  },
  {
    id: 1,
    title: 'exit',
    icon: LogoutIcon,
  }
];
