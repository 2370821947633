import {
  all,
  takeEvery,
  put,
  fork,
  select
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import {loginCode} from "src/utils/loginCode";
import i18n from 'i18next'; 
import {getToastConfig} from "src/utils/index"

export function* GetStatisticsRequest() {
  yield takeEvery(types.GET_STATATISTICS_REQUEST, function* ({ data }) {
    let response =[];
    const direction = yield select((state) => state.App.direction);
    try {
        response = yield Api.Get(Endpoints.GetStatisticsRequest , true);

      if (response) {
        yield put({
          type: types.GET_STATATISTICS_REQUEST_SUCCESS,
          data: { data: response},

        });
      } else
        yield put({
          type: types.GET_STATATISTICS_REQUEST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode );
      toast.error( selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_STATATISTICS_REQUEST_FAIL,
      });
    }
  });
}

export function* GetUserEndpoints() {
  yield takeEvery(types.GET_USER_ENDPOINTS, function* ({ data }) {
    let response =[];
    const direction = yield select((state) => state.App.direction);
    try {
        response = yield Api.Get(Endpoints.GetUserEndpoints , true);

      if (response) {
        yield put({
          type: types.GET_USER_ENDPOINTS_SUCCESS,
          data: { data: response},

        });
        if(response.length>0){
          yield put({
            type: types.SEND_STATISTICS_ENDPOINT,
            data: { id: response[0].id},
          });
        }
      } else
        yield put({
          type: types.GET_USER_ENDPOINTS_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode );
      toast.error( selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_USER_ENDPOINTS_FAIL,
      });
    }
  });
}

export function* SendStatisticsEndpoint() {
  
  yield takeEvery(types.SEND_STATISTICS_ENDPOINT, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      EndpointId: data.id,

    }
    try {
      const response = yield Api.Post(Endpoints.SendStatisticsEndpoint, body, true);
      if (response) {
        yield put({
          type: types.SEND_STATISTICS_ENDPOINT_SUCCESS,
          data: response
        });

      } else
        yield put({
          type: types.SEND_STATISTICS_ENDPOINT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode );
      toast.error( selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.SEND_STATISTICS_ENDPOINT_FAIL,
      });
    }
  });
}

export function* GetStatisticsCu() {
  yield takeEvery(types.GET_STATATISTICS_CU, function* ({ data }) {
    let response =[];
    const direction = yield select((state) => state.App.direction);
    try {
        response = yield Api.Get(Endpoints.GetStatisticsCu , true);

      if (response) {
        yield put({
          type: types.GET_STATATISTICS_CU_SUCCESS,
          data: { data: response},

        });
      } else
        yield put({
          type: types.GET_STATATISTICS_CU_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      // let selected = loginCode.filter((each) => each.code === e.response.data.DicCode );
      // toast.error( selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
      yield put({
        type: types.GET_STATATISTICS_CU_FAIL,
      });
    }
  });
}

export function* GetStatisticsUsedEndpoint() {
  yield takeEvery(types.GET_STATATISTICS_USED_ENDPOINTS, function* ({ data }) {
    let response =[];
    const direction = yield select((state) => state.App.direction);
    try {
        response = yield Api.Get(Endpoints.GetStatisticsUsedEndpoint , true);

      if (response) {
        yield put({
          type: types.GET_STATATISTICS_USED_ENDPOINTS_SUCCESS,
          data: { data: response},

        });
      } else
        yield put({
          type: types.GET_STATATISTICS_USED_ENDPOINTS_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode );
      toast.error( selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.GET_STATATISTICS_USED_ENDPOINTS_FAIL,
      });
    }
  });
}

export function* SendStatisticsWaas() {
  
  yield takeEvery(types.SEND_STATATISTICS_WAAS, function* ({ data }) {
    const direction = yield select((state) => state.App.direction);
    let body = {
      // EndpointId: data.id,
    }
    try {
      const response = yield Api.Post(Endpoints.SendStatisticsWaas, body, true);
      if (response) {
        yield put({
          type: types.SEND_STATATISTICS_WAAS_SUCCESS,
          data: response
        });

      } else
        yield put({
          type: types.SEND_STATATISTICS_WAAS_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode );
      toast.error( selected.length > 0 ? i18n.t(selected[0].titleKey) : e.response.data.title, getToastConfig(direction));
      yield put({
        type: types.SEND_STATATISTICS_WAAS_FAIL,
      });
    }
  });
}

export default function* DashboardSagas() {
  yield all(
    [
      fork(GetStatisticsRequest),
      fork(GetUserEndpoints),
      fork(SendStatisticsEndpoint),
      fork(GetStatisticsCu),
      fork(GetStatisticsUsedEndpoint),
      fork(SendStatisticsWaas)
    ]);
}