import React , { useEffect }from "react";
import WalletView from "src/views/wallet";
import MainLayout from "src/shared/MainLayout";
import { useDispatch , useSelector} from "react-redux";
import WalletActions from "src/redux/wallet/Actions";
import EndpointsActions from "src/redux/Endpoint/Actions";
import PaymentActions from "src/redux/Payment/Actions";

const Dashboard = () => {
    const dispatch = useDispatch();
    const paymentStep = useSelector((state) => state.Payment.paymentStep);
    useEffect(() => {
        dispatch(WalletActions.getWallet());
        dispatch(EndpointsActions.getBlockchainsList());
        dispatch(WalletActions.getParentWallet());
        if(paymentStep === 3){
            dispatch(PaymentActions.resetPaymentData());
        }

    },[])
    return (
        <MainLayout>
            <WalletView />
        </MainLayout>
    )
}
export default Dashboard