import React from "react";
import SendEmail from "../sendEmailVerification/components/sendEmail";
import Verification from "../sendEmailVerification/components/verification";
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "src/redux/Login/Actions";
import { useTranslation } from 'react-i18next';


const Confirmation = (props) => {
  const { t } = useTranslation();
  const LoginState = useSelector((state) => state.Login);
  const { confirmStep, sendSuccessfulEmailAlertModal } = LoginState;
  const dispatch = useDispatch();

  const onCloseSendEmailModalHandler = (show) => {
    dispatch(LoginActions.forgetPasswordModal({ show: false }))
  }

  const onCloseVerificationModalHandler = (show) => {
    dispatch(LoginActions.showSuccessfulEmailAlertModal({ show: false }))
  }

  const ConfirmationStepTarget = (step) => {
    switch (step) {
      case 0:
        return <SendEmail open={props.open} onClose={onCloseSendEmailModalHandler} onConfirm={props.onConfirm} btnText={t("confirm")}/>;
      case 1:
        return <Verification open={sendSuccessfulEmailAlertModal} text={t("If_the_email_entered_is_correct_the_password_recovery_link_has_been_sent")} onClose={onCloseVerificationModalHandler} />;
      default:
        return <SendEmail open={props.open} />;
        break;
    }
  };
  return (
    <>
      {ConfirmationStepTarget(confirmStep)}
    </>
  )
}
export default Confirmation