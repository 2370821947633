export default {

    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_EXCEPTION: 'LOGIN_EXCEPTION',
  
    AUTO_LOGIN: 'AUTO_LOGIN',
  
    GET_USER_INFO: 'GET_USER_INFO',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAIL: 'GET_USER_INFO_FAIL',
    GET_USER_INFO_EXCEPTION: 'GET_USER_INFO_EXCEPTION',
  
    LOGOUT: 'LOGOUT',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAIL: 'LOGOUT_FAIL',
  
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',
    RESET_PASSWORD_EXCEPTION: 'RESET_PASSWORD_EXCEPTION',

    CHANGE_PASSWORD_MODAL: 'CHANGE_PASSWORD_MODAL',
    NEXT_CONFIRMATION_STEP :'NEXT_CONFIRMATION_STEP',
    SHOW_SECCESSFUL_EMAIL_ALERT : "SHOW_SECCESSFUL_EMAIL_ALERT",

    SEND_PASSWORD_TO_EMAIL: 'SEND_PASSWORD_TO_EMAIL',
    SEND_PASSWORD_TO_EMAIL_SUCCESS: 'SEND_PASSWORD_TO_EMAIL_SUCCESS',
    SEND_PASSWORD_TO_EMAIL_FAIL: 'SEND_PASSWORD_TO_EMAIL_FAIL',
    SEND_PASSWORD_TO_EMAIL_EXCEPTION: 'SEND_PASSWORD_TO_EMAIL_EXCEPTION',

    REGISTER: 'REGISTER',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    REGISTER_EXCEPTION: 'REGISTER_EXCEPTION',

    CHANGE_USER_ACCOUNT_MODAL: 'CHANGE_USER_ACCOUNT_MODAL',

    COMPLETE_INFO: 'COMPLETE_INFO',

    FORGET_PASSWORD: 'FORGET_PASSWORD',
    FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
    FORGET_PASSWORD_FAIL: 'FORGET_PASSWORD_FAIL',
    FORGET_PASSWORD_EXCEPTION: 'FORGET_PASSWORD_EXCEPTION',

    SET_SHOW_RULES_MODAL: 'SET_SHOW_RULES_MODAL',

    CONFIRM_EMAIL: 'CONFIRM_EMAIL',
    CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
    CONFIRM_EMAIL_FAIL: 'CONFIRM_EMAIL_FAIL',
    CONFIRM_EMAIL_EXCEPTION: 'CONFIRM_EMAIL_EXCEPTION',

  };