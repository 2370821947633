import React,{useEffect} from "react";
import { CardContainer } from "src/shared/generalStyles";
import { PaymentContainer } from '../styles'
import { Grid, Box, Slider } from "@mui/material";
import Button from "src/kit/Button";
import { NumberSeprator } from "src/utils/index"
import  paymentCrtl  from "../payment.controller";
import { useTranslation } from 'react-i18next';

const Payment = () => {
    const { handleChange, handleNextStep, valueLabelFormat , priceList, cuAndDuration,value ,setSliderData ,handlegetCu,sliderData } = paymentCrtl();
    const { t } = useTranslation();

    useEffect(() => {
        setSliderData({
            ...sliderData,
            steps: priceList,
            max: priceList.length - 1
        });
        priceList.length >0 && handlegetCu(priceList[0]);

    }, [priceList])

    return (
        <CardContainer>
            <PaymentContainer>
                <Grid container display="flex" justifyContent="start">
                    <Box className="text_bold">{t("RequestedCUAmount")}</Box>
                </Grid>
                {
                    priceList.length > 0 ?
                        <Grid container display="flex" justifyContent="center" alignItems="center">
                            <Grid item md={12} display="flex" justifyContent="center" alignItems="center">

                                <Box sx={{ width: 500 }}>
                                    <Slider
                                        value={value}
                                        min={0}
                                        max={priceList.length - 1}
                                        step={1}
                                        onChange={handleChange}
                                        valueLabelDisplay="on"
                                        valueLabelFormat={valueLabelFormat}
                                        marks
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={12} my={2} display="flex" justifyContent="center" alignItems="center">
                                <Box id="price-box">
                                    {NumberSeprator(cuAndDuration?.computationUnits)} {t("computationUnit")}
                                    <br />
                                    {NumberSeprator(cuAndDuration?.duration)}  {t("day")}
                                </Box>
                            </Grid>
                        </Grid> : <Box className="loader"></Box>
                }

                <Grid container display="flex" justifyContent="end" id="payment-btn-container">
                    <Grid item xs={3} p={1} md={3} >
                        <Button
                            onConfirm={() => { handleNextStep(1) }} text={t("pay")}
                        ></Button>
                    </Grid>
                </Grid>

            </PaymentContainer>

        </CardContainer>
    )
}

export default Payment