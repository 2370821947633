import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#0F183F"
        fillRule="evenodd"
        d="M7 0L.002.002 0 8h7V0zm8.998.002L9 0v6h7L15.998.002zM0 10h7v6l-6.998-.003L0 10zm16-2H9v8l6.998-.003L16 8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
