import { LoginFormContainer, LogoContainer } from "./styles";
import { Box, Grid } from "@mui/material";
import LogoIcon from "src/assets/icons/LogoIcon1";
import LogoBox from "src/assets/icons/LogoBox"
import LoginForm from "./loginForm"

const Login = ({ setMode }) => {
   return (
      <LoginFormContainer>
         <Grid container id="login-container">
            <Grid item xs={12} sm={5}>
               <LogoContainer>
                  <Box id="logo">
                     <LogoIcon  />
                  </Box>
                  <LogoBox />
                  {/* <a href="https://polaristech.ir/">polaristech.ir</a> */}
               </LogoContainer>
            </Grid>
            <Grid item xs={12} sm={7}>
               <LoginForm setMode={setMode} />
            </Grid>
         </Grid>
      </LoginFormContainer>
   )
}
export default Login