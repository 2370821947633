export default {
    login: "/",
    dashboard: "/dashboard",
    endpoint: "/endpoint",
    completeInfo : "/completeInfo",
    wallet:"/wallet",
    auth:"/auth/reset_password",
    payment :"/payment",
    paymentHistory :"/payment/paymentHistory",
    purchase :"/payment/purchase",
    completeRegister :"/confirm-email"

};