import styled from 'styled-components';

export const MenuListContainer = styled.div`
  width: 100%;
  display : flex;
  flex-direction :column;
`;

export const MenuItem = styled.div`
  width: 100%;
  display : flex;

`;

export const MenuListIcon = styled.div`
display : flex;
  width: auto;
  svg {
    width: 20px !important;
    height: 20px !important;
    margin: 10px;
  }
  cursor: pointer;
`;

export const MenuListText = styled.div`
color : ${(props) => props.theme.textPrimary};;
align-items: center;
display : flex;
  width: auto;
  font-size: ${(props) => props.theme.fontSizeXs};
  cursor: pointer;
`;
