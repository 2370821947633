import styled from "styled-components";
import Column from "src/kit/Column";
import Row from "src/kit/Row";

export const FormRow = styled(Column)`
   width : 100%;
   padding:0 10px;
   padding-bottom :0;
   box-sizing : border-box;
   .text-alert{
      color : red;
      font-size: 10px;
      width: 100%;
      height :20px;
   }
`;
export const FilterContainer = styled(Row)`
   width : 100%;
   padding-bottom :0;
   box-sizing : border-box;
   .filter-box{
      width : 100%;
      justify-content: flex-start;
   }
   .btn-filter{
      font-family: ${(props) => (props.theme.fontDefault)};
      padding:5px;
      background-color : ${props => props.theme.primary};
      color:${props => props.theme.white}; 
      width : 100px;
      border:none;
      height :35px;
      cursor : pointer;
    }
`;

export const FormContainer = styled(Column)`
  direction : ${props => props.direction} !important;  
  padding : 10px 20px;
  border-top :1px solid #ccc;
  form {
   width: 100%;
  }
  .Select-menu-outer {
   text-align : ${(props) => props.direction === "rtl" ? "right" : "left"};
   padding-right : ${(props) => props.direction === "rtl" ? "10px" : "unset"};
   padding-left : ${(props) => props.direction === "ltr" ? "10px" : "unset"};
}
input{
   direction : ${props => props.direction}
  }
`;

export const TableActions = styled(Column)`
 .table_btn{
   border :1px solid #ABAFB3;
   padding: 0 10px;
   border-radius:5px;
   color: #ABAFB3;
   height: 31px;
 }
`;