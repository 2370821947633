import styled from 'styled-components';
import Col from "src/kit/Column";

export const RegisterCompleteFormWrapper = styled(Col)`
    height :100vh;
    background-repeat:no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center;
    background-image :  url(${(props) => props.src});
    position:relative;

`;
export const ContentWrapper = styled(Col)`
    height :65vh;
    position:absolute;
    width: 30%;
    right: 12%;
    top: 11%;
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius};
    #login-container{
        height:100%; 
    }
`;

