import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="16"
      fill="none"
      viewBox="0 0 25 16"
    >
      <path
        fill="#1F499F"
        d="M24.207 8.707a1 1 0 000-1.414L17.843.929a1 1 0 10-1.414 1.414L22.086 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM.5 9h23V7H.5v2z"
      ></path>
    </svg>
  );
}

export default Icon;
