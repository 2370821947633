

export const theme = {
    primary: "#ED137D",
    primaryDark: "#0F183F",
    primaryLight: "#FFFFFF",
    primaryLock :"#ed137d87",

    secondary: "#E5E5E5",
    secondaryDark: "#1E1E1E",
    secondaryLight: "#AFAEAE",

    text: "#484848",
    textLight: "#AAAAAA",
    lightGary: "#text",
    baseFontColor: "#484848",
    lightFontColor : "#999999",
    primaryColor:"#707070",
    white: "#fff",
    textSecondary:"#707070",

    btnColor:"#125C69",
    borderRadius:"10px",

    fontSizeMd: "1rem",
    fontSizeLg: "1.25rem",
    fontSizeXl: "1.5rem",
    fontSizeXXl: "2.125rem",
    fontSizeSm: "0.875rem",
    fontSizeXs: "0.75rem",
    fontSizeXXs: "0.625rem",

    fontDefault: "Vazir",
    fontBold: "VazirBold",
    fontBlack: "VazirBlack",
    fontLight: "VazirLight",
    
 };
 
  