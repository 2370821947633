import React, {  useState, useEffect } from "react";
import { FullPageWrapper, FullPageContainer, ContentViewWrapper, ContentViewContainer, BodyContainer } from "./style"
import SubSystemMenu from "./SubSystemMenu";
import { useHistory } from "react-router";
import { modulesList } from "./modules";
import { ToastContainer } from 'react-toastify';
import { useSelector } from "react-redux";
import Header from "src/shared/Header";

const MainLayout = ({
  children,
  hasIndustry
}) => {
  const modules = modulesList;

  const {location} = useHistory();
  const [activeSystem, setActiveSystem] = useState(modules[0]);
  const [activeSubSystem, setActiveSubSystem] = useState(
    modules[0].subSystem[0]
  );
  const [activeSubSystemMenu, setActiveSubSystemMenu] = useState(null);
  const [selected, setSelected] = useState(null);
  const direction = useSelector((state) => state.App.direction);


  const selectSubSystemHandler = (subSystem) => {
    setActiveSubSystem({
      ...activeSubSystem,
      ...subSystem,
    });
  };
  const systemLocationDetectHandler = () => {

    let locationPathArr = location.pathname.split("/");
    let locationParentPath =
      locationPathArr.length > 1 ? "/" + locationPathArr[1] : "/";
    let system = modules.filter((each) =>
      location.pathname.includes(each.basePath)
    )[0];
    let subsSystem = system.subSystem.filter(
      (each) => each.path === locationParentPath
    )[0];
    let subsSystemMeu = subsSystem?.menus?.filter(
      (each) => each.path === location.pathname
    )[0];
    setSelected(subsSystem.id);


    if (activeSystem) {
      if (activeSystem.id !== system.id) {
        setActiveSystem({
          ...activeSystem,
          ...system,
        });
      }
    } else {
      setActiveSystem({
        ...activeSystem,
        ...system,
      });
    }

    if (activeSubSystem) {
      if (activeSubSystem.id !== subsSystem.id) {
        setActiveSubSystem({
          ...activeSubSystem,
          ...subsSystem,
        });
      }
    } else {
      setActiveSubSystem({
        ...activeSubSystem,
        ...subsSystem,
      });
    }

    if (activeSubSystemMenu) {
      if (activeSubSystemMenu.id !== subsSystemMeu.id) {
        setActiveSubSystemMenu({
          ...activeSubSystemMenu,
          ...subsSystemMeu,
        });
      }
    } else {
      setActiveSubSystemMenu({
        ...activeSubSystemMenu,
        ...subsSystemMeu,
      });
    }
  };

  const toggleHandler = (s) => {
    setSelected(s);
  };
  useEffect(() => {
    systemLocationDetectHandler();
  }, []);

  return (
  
      <FullPageWrapper direction={direction}>
        <FullPageContainer >
          <ContentViewWrapper >
            <SubSystemMenu
              activeSystem={activeSystem}
              activeSubSystem={activeSubSystem}
              activeSubSystemMenu={activeSubSystemMenu}
              selectSubSystemHandler={selectSubSystemHandler}
              selected={selected}
              toggleHandler={toggleHandler}
            />
            <BodyContainer>
              <Header activeSubSystem={activeSubSystem} activeSubSystemMenu={activeSubSystemMenu}/>
              <ContentViewContainer >{children}</ContentViewContainer>
            </BodyContainer>
          </ContentViewWrapper>
        </FullPageContainer>
        <ToastContainer />
      </FullPageWrapper>

  );
};
MainLayout.defaultProps = {
 
};
export default MainLayout;
