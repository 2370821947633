export default {

    ADD_COMPUTATION_UNIT: 'ADD_COMPUTATION_UNIT',
    ADD_COMPUTATION_UNIT_SUCCESS: 'ADD_COMPUTATION_UNIT_SUCCESS',
    ADD_COMPUTATION_UNIT_FAIL: 'ADD_COMPUTATION_UNIT_FAIL',
    ADD_COMPUTATION_UNIT_EXCEPTION: 'ADD_COMPUTATION_UNIT_EXCEPTION',

    GET_CU: 'GET_CU',
    GET_CU_SUCCESS: 'GET_CU_SUCCESS',
    GET_CU_FAIL: 'GET_CU_FAIL',
    GET_CU_EXCEPTION: 'GET_CU_EXCEPTION',

    GET_PRICE_LIST: 'GET_PRICE_LIST',
    GET_PRICE_LIST_SUCCESS: 'GET_PRICE_LIST_SUCCESS',
    GET_PRICE_LIST_FAIL: 'GET_PRICE_LIST_FAIL',
    GET_PRICE_LIST_EXCEPTION: 'GET_PRICE_LIST_EXCEPTION',

    GET_PAYMENT_LIST: 'GET_PAYMENT_LIST',
    GET_PAYMENT_LIST_SUCCESS: 'GET_PAYMENT_LIST_SUCCESS',
    GET_PAYMENT_LIST_FAIL: 'GET_PAYMENT_LIST_FAIL',
    GET_PAYMENT_LIST_EXCEPTION: 'GET_PAYMENT_LIST_EXCEPTION',

    NEXT_PAYMENT_STEP :'NEXT_PAYMENT_STEP',
    PREV_PAYMENT_STEP :'PREV_PAYMENT_STEP',

    MAKE_INVOICE: 'MAKE_INVOICE',
    MAKE_INVOICE_SUCCESS: 'MAKE_INVOICE_SUCCESS',
    MAKE_INVOICE_FAIL: 'MAKE_INVOICE_FAIL',
    MAKE_INVOICE_EXCEPTION: 'MAKE_INVOICE_EXCEPTION',

    DO_PAYMENT: 'DO_PAYMENT',
    DO_PAYMENT_SUCCESS: 'DO_PAYMENT_SUCCESS',
    DO_PAYMENT_FAIL: 'DO_PAYMENT_FAIL',
    DO_PAYMENT_EXCEPTION: 'DO_PAYMENT_EXCEPTION',

    RESET_PAYMENT_DATA: 'RESET_PAYMENT_DATA',

  
  };