import React, { useEffect } from "react";
import DashboardView from "src/views/dashboard";
import MainLayout from "src/shared/MainLayout";
import { useDispatch ,useSelector } from "react-redux";
import UserActions from "src/redux/User/Actions";
import DashboardActions from "src/redux/dashboard/Actions";
import PaymentActions from "src/redux/Payment/Actions";


const Dashboard = () => {
    const dispatch = useDispatch();
    const paymentStep = useSelector((state) => state.Payment.paymentStep);

    useEffect(() => {
        dispatch(UserActions.getProfileInfo());
        dispatch(DashboardActions.getStatisticsRequest());
        dispatch(DashboardActions.getUserEndpoints());
        dispatch(DashboardActions.getStatisticsCu());
        dispatch(DashboardActions.getStatisticsUsedEndpoint());
        dispatch(DashboardActions.sendStatisticsWaas());
        
        if(paymentStep === 3){
            dispatch(PaymentActions.resetPaymentData());
        }

    },[])
    return (
        <MainLayout>
            <DashboardView />
        </MainLayout>
    )
}
export default Dashboard