import fileDownload from "file-saver";
import { toast } from 'react-toastify';
import { getToastConfig } from "src/utils";
import i18n from 'i18next'; 


export const downloadFile = (data, prifix = "report", ext = "pdf" ,direction ="ltr") => {
  var blob = new Blob([data], {type: "application/pdf;charset=utf-8"});
  fileDownload(blob, prifix + "." + ext);
  toast.success(i18n.t("DoneSuccessfully"), getToastConfig(direction));
};

