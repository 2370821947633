import styled from "styled-components";
import RightSignIcon from "../../../assets/icons/RightSignIcon";
import Col from "../../../kit/Column";
import { theme } from "../../../utils/theme";

export const StyledPaginationList = styled(Col)`
  width: 100%;
  margin-top: 20px;
  flex-direction: row;
  position : absolute;
  bottom : 0;
  display: ${(props) => props.show === false && "none"};
  height : 40px;
  svg {
    fill: #666666;
    width: 15px;
    height: 15px;
    transform: rotate(270deg);
  }
`;
export const StyledPagination = styled(Col)`
  text-align: center;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  margin-right: 5px;
  background-color: ${(props) =>
    props.selected === true
      ? props.theme.primary || theme.primary
      : props.disable === true
      ? props.theme.lightGray
      : "white"};
  color: ${(props) =>
    props.selected === true
      ? "white"
      : props.disable === true
      ? props.theme.greenBlue
      : "#333333"};
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.hoverable === true && (props.theme.primary || theme.primary)};
    color: ${(props) => props.hoverable === true && "white"};
    transition: all 300ms;
  }
`;
export const StyledArrow = styled(RightSignIcon)``;