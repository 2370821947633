import React from "react";
import GenerateApi from "./components/generate";
import Verification from "./components/showApi";
import { useSelector, useDispatch } from "react-redux";
import EndpointsActions from "src/redux/Endpoint/Actions";
import { useTranslation } from 'react-i18next';

const Confirmation = (props) => {
  const { t } = useTranslation();

  const endpointState = useSelector((state) => state.Endpoint);
  const { confirmStep, sendSuccessfulEmailAlertModal, apiKey } = endpointState;
  const dispatch = useDispatch();


  const onCloseSendEmailModalHandler = (show) => {
    dispatch(EndpointsActions.setShowGenerateApiKeyModal({ visible: show }))
  }
  const sendEmailHandler = () => {
    dispatch(EndpointsActions.generateApiKey({id :props.selected.id !=="" ? props.selected.id : props.selected.waasApiKeyId}));
  }
  const onCloseShowApiModalHandler = (show) => {
    dispatch(EndpointsActions.setShowApiKeyModal({ visible: show }))
  }
  const ConfirmationStepTarget = (step) => {
    switch (step) {
      case 0:
        return <GenerateApi
          open={props.open}
          onClose={onCloseSendEmailModalHandler}
          onConfirm={sendEmailHandler} title={props.title}
          btnText={t("GetNewAPIKey")} />;
      case 1:
        return <Verification open={sendSuccessfulEmailAlertModal} apiKey={apiKey} onClose={onCloseShowApiModalHandler} />;
      default:
        return <GenerateApi open={props.open} />;
        break;
    }
  };
  return (
    <>
      {ConfirmationStepTarget(confirmStep)}
    </>
  )
}
export default Confirmation