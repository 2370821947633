import React from "react";
import { useDispatch } from "react-redux";
import StyledSubMenu from "src/shared/StyledSubMenu"
import { UserMenuConfig } from "./UserMenuConfig";
import {
  MenuListContainer,
  MenuItem,
  MenuListIcon,
  MenuListText,
} from "./style";
import { useSelector } from "react-redux";
import AppActions from "../../../redux/App/Actions";
import LoginActions from "../../../redux/Login/Actions";
import UserActions from "src/redux/User/Actions"
import { useTranslation } from 'react-i18next';

const UserMenu = (props) => {
  const { t } = useTranslation();
  const { visible, setShowSubMenu } = props;
  const theme = useSelector((state) => state.App.theme);
  const direction = useSelector((state) => state.App.direction);


  const dispatch = useDispatch();
  const userMenuActions = (id) => {

    setShowSubMenu(false);
    switch (id) {
      case 1:
        logoutHandler();
        break;
      case 0:
        ChangeUserAccountModalHandler(true);
        break;
      case 2:
        ChangePasswordModalHandler(true);
        break;
      default:
        break;
    }
    dispatch(AppActions.userMenu(false));
  };

  const logoutHandler = () => {
    dispatch(LoginActions.logOut())
  
};
const ChangeUserAccountModalHandler = (show) => {
  dispatch(UserActions.showUserProfileModal({ visible: show }));
}
const ChangePasswordModalHandler = (show) => {
  dispatch(UserActions.showChangePasswordModal({ visible: show }));
}
return (
  <>
    <StyledSubMenu visible={visible} placement={direction === "ltr" ? "left" : "right" } direction={direction}>
      <MenuListContainer>
        {UserMenuConfig.map((item) => {
          let Icon = item.icon;
          return (

            <MenuItem
              key={item.id}
              onClick={() => {
                userMenuActions(item.id);
              }}
            >

              <MenuListIcon>
                <Icon pathFill={theme.textSecondary} />
              </MenuListIcon>

              <MenuListText>

              {t(item.title)}

              </MenuListText>
            </MenuItem>

          );
        })}
      </MenuListContainer>
    </StyledSubMenu>

  </>
);
};
export default UserMenu;
