import Row from "src/kit/Row";
import { TableActions } from "./styles";
import { Grid, Box } from "@mui/material";
import WalletIcon from "src/assets/icons/WalletIcon";
import AddressCardIcon from "src/assets/icons/AddressCardIcon";
import { useTranslation } from 'react-i18next';

const WalletTableConfig = () => {
  const { t } = useTranslation();
  return {
    showHeader: true,

    row: [
      {
        headerTitle: t('rowIndex'),
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: t('label'),
        displayTitle: "label",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.label !== "" ? props.row.label : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('blockchainName'),
        displayTitle: "blockchainName",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.blockchainName !== "" ? props.row.blockchainName : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('network'),
        displayTitle: "networkName",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.networkName !== "" ? props.row.networkName : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('createdDate'),
        displayTitle: "createdDate",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.createdDate !== "" ? props.row.createdDate : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('addressCount'),
        displayTitle: "addressCount",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.addressCount !== "" ? props.row.addressCount : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('details'),
        displayTitle: "id",
        size: 4,
        responsiveShow: false,
        element: (props) => {
          return (
            <TableActions>
              <Grid container>
                <Grid item p={1} display="flex" >
                  <Box type="detail" className="table_btn tooltip"
                   >
                    <Box className="tooltiptext">{t('addresses')}</Box>
                    <AddressCardIcon /></Box>
                </Grid>
                <Grid item p={1} display="flex" >
                  <Box type="detail" className="table_btn  tooltip"
                    >
                    <Box className="tooltiptext">{t('transactions')}</Box>
                    <WalletIcon /></Box>
                </Grid>
              </Grid>
            </TableActions>
          );
        },
      },
    ],
  };
};
export default WalletTableConfig;