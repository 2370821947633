import React, { useState ,useEffect} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

// import "./styles.css";

export const BtnWrapper = styled.div`
.app {
  text-align: center;
}

button {
  background: ${props => props.theme.primary};
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  position: relative;
  padding: 0 16px;
  width : 100%;
}

/* button:active {
  background: #f79801;
} */

.text {
  font-weight: bold;
  transition: opacity 250ms;
  transition-delay: 250ms;
  width: 100%;
}

button.loading .text {
  opacity: 0;
  transition-delay: 0s;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: white;
  opacity: 0;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: opacity 250ms;
  animation: rotate-spinner 1s linear;
  animation-iteration-count: infinite;
}

button.loading .spinner {
  opacity: 1;
  transition-delay: 250ms;
}
.disabled-button {
  background-color: ${props => props.theme.primaryLock};
}
@keyframes rotate-spinner {
  100% {
    transform: rotate(360deg);
  }
}
`;

const Button = (props) => {
    const {ButtonLoading,onConfirm,disabled =false} = props;
  const [isLoading, setIsLoading] = useState(ButtonLoading);
  useEffect(() => {
    setIsLoading(ButtonLoading);
  }, [ButtonLoading]);
  const handleOnClick = () => {
   if(onConfirm) onConfirm();
    if (isLoading) return;

    setIsLoading(true);
   // setTimeout(() => setIsLoading(false), 2000);
  };

  return (
    <BtnWrapper>
    <button
      // className={isLoading ? "loading" : undefined isChecked ? "" : "disabled-button"}
      className={`${isLoading ? "loading" : ""} ${disabled === false ? "" : "disabled-button"}`}
      onClick={onConfirm}
      disabled={disabled}
    >
      <div className="spinner" />
      <p className="text">{props.text}</p>
    </button>
    </BtnWrapper>
  );
}
export default Button;