import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="36"
      fill="none"
      viewBox="0 0 29 36"
    >
      <path
        fill="#d5d5d5"
        d="M26.365 0c.638.173 1.236.411 1.696.918.558.616.767 1.345.767 2.161-.004 5.294-.002 10.588-.002 15.882 0 2.589.005 5.177-.002 7.766-.004 1.452-.832 2.533-2.202 2.881-.234.06-.487.078-.73.068-.47-.018-.822-.366-.89-.842a.98.98 0 01.63-1.06c.043-.016.09-.027.134-.039.99-.243 1.075-.352 1.075-1.378 0-7.707 0-15.414-.002-23.12 0-.175-.01-.351-.038-.524-.062-.392-.295-.625-.687-.689a2.872 2.872 0 00-.452-.038c-5.366-.002-10.731-.003-16.097 0-.208 0-.43.008-.622.076-.387.137-.513.473-.567.855-.087.623-.543.986-1.11.907-.572-.08-.914-.548-.851-1.167.137-1.36.98-2.305 2.321-2.602C8.779.046 8.819.02 8.86 0h17.506-.001z"
      ></path>
      <path
        fill="#d5d5d5"
        d="M0 21.129V9.32C0 8.09.496 7.126 1.63 6.599c.42-.195.924-.288 1.39-.29 5.46-.02 10.919-.018 16.38-.01 1.833.003 3.025 1.217 3.026 3.053.002 7.86.002 15.721 0 23.58 0 1.857-1.202 3.058-3.056 3.058-5.437.002-10.873.002-16.309 0-1.855 0-3.059-1.201-3.06-3.054-.002-3.935 0-7.872 0-11.808zm1.979-.005v11.668c0 .927.283 1.207 1.223 1.207H19.23c.918 0 1.202-.282 1.202-1.192V9.47c0-.899-.28-1.181-1.169-1.181H3.165c-.907 0-1.186.283-1.186 1.202v11.633z"
      ></path>
    </svg>
  );
}

export default Icon;
