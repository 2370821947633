import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#7BB31A"
        d="M35.47 0c1.154.45 1.998 1.162 2.06 2.503.065 1.396-.856 2.545-2.247 2.702-1.488.167-2.995.176-4.48.374-5.52.737-10.434 2.914-14.708 6.466-4.892 4.065-8.317 9.15-9.832 15.348C3.768 37.6 5.926 46.769 12.888 54.69c4.608 5.244 10.437 8.455 17.328 9.502 9.066 1.376 17.174-.914 24.147-6.924 4.604-3.966 7.736-8.908 9.21-14.808.53-2.122.669-4.342.973-6.52.053-.38.019-.773.063-1.156.185-1.58 1.285-2.544 2.794-2.462 1.415.077 2.45 1.227 2.445 2.756-.008 3.083-.355 6.136-1.252 9.084C64.68 57.014 56.28 65.469 43.263 68.785c-17.301 4.407-34.8-5.018-41.086-21.739A33.176 33.176 0 01.12 37.631c-.016-.22-.079-.438-.12-.656v-4.093c.056-.49.113-.98.167-1.473.836-7.515 3.707-14.145 8.773-19.767C14.315 5.673 20.98 1.946 28.908.543 30.221.311 31.552.18 32.877 0h2.592z"
      ></path>
      <path
        fill="#7BB31A"
        d="M32.685 41.19c.17-.273.277-.543.465-.731C43.352 30.27 53.56 20.094 63.761 9.908c.764-.763 1.584-1.285 2.719-1.068 1.914.367 2.777 2.58 1.606 4.138-.176.234-.39.443-.6.651-10.929 10.906-21.86 21.81-32.793 32.712-1.555 1.551-3.02 1.522-4.502-.086-4.238-4.598-8.474-9.197-12.707-13.8-1.13-1.229-1.176-2.796-.137-3.848 1.079-1.092 2.746-1.048 3.878.154 1.544 1.637 3.055 3.306 4.58 4.962 2.234 2.423 4.466 4.848 6.88 7.467z"
      ></path>
    </svg>
  );
}

export default Icon;
