import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        fill="#0F183F"
        d="M15.75 7a.342.342 0 01-.144-.032L4.991 2.208 2.765 6.804a.35.35 0 11-.63-.308l2.377-4.9a.351.351 0 01.458-.168l10.923 4.9c.175.08.256.287.175.462a.35.35 0 01-.318.206V7z"
      ></path>
      <path
        fill="#0F183F"
        d="M11.988 7a.342.342 0 01-.143-.032L5.958 4.326 4.758 6.8a.35.35 0 11-.63-.308l1.347-2.779a.351.351 0 01.458-.168l6.199 2.78c.175.08.255.286.175.461a.35.35 0 01-.319.207V7z"
      ></path>
      <path
        fill="#0F183F"
        d="M19.25 9.8c.192 0 .35-.158.35-.35v-1.4a1.75 1.75 0 00-1.75-1.75h-.529L13.22.156a.348.348 0 00-.486-.098L9.632 2.17a.349.349 0 00-.091.487.346.346 0 00.486.091l2.81-1.91 3.648 5.462H1.75A1.75 1.75 0 000 8.05v11.2C0 20.215.784 21 1.75 21h16.1a1.75 1.75 0 001.75-1.75v-1.4a.351.351 0 00-.35-.35.351.351 0 00-.35.35v1.4c0 .578-.473 1.05-1.05 1.05H1.75c-.578 0-1.05-.472-1.05-1.05V8.05C.7 7.472 1.172 7 1.75 7h16.096c.578 0 1.05.473 1.05 1.05v1.4c0 .193.158.35.35.35h.004z"
      ></path>
      <path
        fill="#0F183F"
        d="M19.95 16.8h-4.9a3.155 3.155 0 01-3.15-3.15 3.155 3.155 0 013.15-3.15h4.9c.578 0 1.05.472 1.05 1.05v4.2c0 .577-.472 1.05-1.05 1.05zm-4.9-5.6a2.453 2.453 0 00-2.45 2.45 2.453 2.453 0 002.45 2.45h4.9c.193 0 .35-.158.35-.35v-4.2a.351.351 0 00-.35-.35h-4.9z"
      ></path>
      <path
        fill="#0F183F"
        d="M15.05 15.05a1.4 1.4 0 110-2.8 1.4 1.4 0 010 2.8zm0-2.1c-.385 0-.7.315-.7.7 0 .384.315.7.7.7.385 0 .7-.316.7-.7 0-.386-.315-.7-.7-.7zM5.95 9.8h-2.8a.351.351 0 01-.35-.35c0-.193.157-.35.35-.35h2.8c.192 0 .35.157.35.35 0 .192-.158.35-.35.35zM7.35 11.9h-4.2a.351.351 0 01-.35-.35.35.35 0 01.35-.35h4.2a.35.35 0 01.35.35c0 .192-.158.35-.35.35z"
      ></path>
    </svg>
  );
}

export default Icon;