import styled from "styled-components";
import Row from "../../kit/Row";

export const HeaderWrapper = styled(Row)`
  width: 100%;
  height :70px;
  background-color:  ${(props) => (props.theme.primaryLight)} ;
  padding: 0 30px;
  justify-content : flex-end;
  padding :0 20px;
  box-sizing: border-box;
`;
export const LogoContainer = styled(Row)`
position: relative;

  color: ${(props) => props.theme.textLight};
  font-size:  ${props => props.theme.fontSizeLg};
  font-family: ${(props) => (props.theme.fontDefault)};
  width:50px;
  height:50px;
  border-radius: 50%;
  background: ${(props) => (props.theme.secondary)};
  svg path {
      stroke :  ${(props) => (props.theme.textLight)} !important;
  }
  #logout-container{
    display: flex;
    align-items: center;
    cursor:pointer;
  }

  }
`;

export const TitleContainer = styled(Row)`
background-color: #E8EDF8;
height :40px;
width: 100%;
justify-content: flex-start;
padding :0 30px;
box-sizing :border-box;
a{
  color: ${(props) => props.theme.primaryDark};
  font-size:  ${props => props.theme.fontSizeLg};
  font-family: ${(props) => (props.theme.fontBold)};
}

a:hover{
  text-decoration :none;
}
`;