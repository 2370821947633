import types from "./Types";
import Storage from "../../storage";
import Keys from "../../storage/NameSpace";
let localUserInfo = Storage.get(Keys.userInfo)
  ? Storage.get(Keys.userInfo)
  : {};
const initState = {
  activeMode: "login",
  isLoggedin: Storage.get(Keys.isLoggedin) ? true : false,
  exception: null,
  loading: false,
  userInfo: {
    ...localUserInfo,
  },
  confirmStep: 0,
  showforgetPasswordModal: false,
  sendSuccessfulEmailAlertModal: false,
  showChangeAccountUserModal: false,
  firstLoggedin: Storage.get(Keys.firstLoggedin) ? true : false,
  showRulesModal: false,
  activateLoading: true,
  activateStatus: false,
};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.LOGIN_SUCCESS:
      Storage.set(Keys.token, action.data.token);
      Storage.set(Keys.isLoggedin, true);
      Storage.set(Keys.firstLoggedin, false);
      return {
        ...state,
        isLoggedin: true,
        firstLoggedin: false
      };

    case types.COMPLETE_INFO:
      return {
        ...state,
        firstLoggedin: false
      }
    case types.REGISTER_SUCCESS:

      return {
        ...state,

      }
    case types.LOGOUT_SUCCESS:
      Storage.logOut();
      return {
        ...state,
        isLoggedin: false,
      };
    case types.CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        showforgetPasswordModal: action.data.visible
      }
    case types.SEND_PASSWORD_TO_EMAIL:
      return {
        ...state,
        showforgetPasswordModal: false,
        sendSuccessfulEmailAlertModal: true,
        confirmStep: 1
      }

    case types.SHOW_SECCESSFUL_EMAIL_ALERT:
      return {
        ...state,
        sendSuccessfulEmailAlertModal: action.data.visible,
        confirmStep: 0

      }
    case types.CHANGE_USER_ACCOUNT_MODAL:
      return {
        ...state,
        showChangeAccountUserModal: action.data.visible,
      }
    case types.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        showforgetPasswordModal: false,
        sendSuccessfulEmailAlertModal: true,
        confirmStep: 1
      }
    case types.SET_SHOW_RULES_MODAL:
      return {
        ...state,
        showRulesModal: action.data.visible,
      }
    case types.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        activateLoading: false,
        activateStatus: true,
      }
      case types.CONFIRM_EMAIL_FAIL:
        return {
          ...state,
          activateLoading: false,
          activateStatus: false,
        }
    default:
      return state;
  }
}