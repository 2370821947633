

import React, { useState } from "react";
import PaymentActions from "src/redux/Payment/Actions";
import { useDispatch, useSelector } from "react-redux";
import { TableWrapper, CardContainer } from "src/shared/generalStyles";
import Table from "src/shared/table";
import PaymentHistoryTableConfig from "./PaymentHistoryTableConfig";
import { FilterContainer } from "./styles";
import PaymentHistoryFilter from "./PaymentHistoryFilter"
import { Grid } from "@mui/material";

const PaymentHistory = () => {

  const dispatch = useDispatch();
  const PaymentState = useSelector((state) => state.Payment);
  const { takePaymentHistory, skipPaymentHistory,paymentHistoryList, paymentHistoryLoading, count } = PaymentState;
  const [filterState, setFilterState] = useState({
    startDateFrom: "",
    startDateTo: ""
  });


  //////////////////////////////////////
  const pageHandler = (res) => {
    dispatch(
      PaymentActions.getPaymentList({
        offset: res,
        limit: takePaymentHistory,
        startDateFrom: filterState.startDateFrom,
        startDateTo: filterState.startDateTo,
      })
    );
  }

  return (
    <>
      <CardContainer>
      <FilterContainer>
          <Grid container>
            <Grid item md={12} className="filter-box" mb={2}>
              <PaymentHistoryFilter filterState={filterState} setFilterState={setFilterState} />
            </Grid>
          </Grid>

        </FilterContainer>
        <TableWrapper height="calc(100% - 60px)">
          <Table
            tblConfig={PaymentHistoryTableConfig()}
            tblData={paymentHistoryList}
            showPaging={true}
            pageHandler={(res) => {
              pageHandler(res);
            }}
            skip={skipPaymentHistory}
            take={takePaymentHistory}
            count={count}
            loading={paymentHistoryLoading}
          />
        </TableWrapper>
      </CardContainer>


    </>
  )
}

export default PaymentHistory;