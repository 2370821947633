import React from "react";
import { DashboardWrapper } from "./styles"
import {  Grid } from "@mui/material";
import PieChart from "./components/piechart";
import RecentEndPoint from "./components/recentEndPoint";
import RequestStatus from "./components/requestStatus";
import MyEndpoint from "./components/myEndpoint";
import Waas from "./components/waas";
import subSystemMenuCrtl from "./dashboard.controllers"

const Dashboard = () => {
    const { direction} = subSystemMenuCrtl();
    return (
        <DashboardWrapper direction={direction}>
            <Grid container>
                <Grid item xs={6} p={1}>
                    <PieChart />
                </Grid>
                <Grid item xs={6} p={1}>
                    <RequestStatus />
                </Grid>
                <Grid item xs={12} p={1}>
                    <MyEndpoint />
                </Grid>
                <Grid item xs={12} p={1}>
                    <Waas />
                </Grid>
                <Grid item xs={12} p={1}>
                    <RecentEndPoint />
                </Grid>
            </Grid>
        </DashboardWrapper>
    )
}
export default Dashboard