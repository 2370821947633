import React from "react";
import Payment from "./components/Payment";
import PaymentStatus from "./components/PaymentStatus";
import PrePayment from "./components/PrePayment";
import { useSelector } from "react-redux";


const Purchase = () => {
    const paymentState = useSelector((state) => state.Payment);
    const { paymentStep  } = paymentState;
    const PaymentStepTarget = (step) => {
        switch (step) {
            case 1:
                return <Payment/>;
            case 2:
                return <PrePayment />;
            case 3:
                return <PaymentStatus />;
            default:
                return <Payment />;
                break;
        }
    };
    return (
        <>
            {PaymentStepTarget(paymentStep)}
        </>
    )
}

export default Purchase