import React, { useState } from "react";
import { FormContainer } from "./styles";

import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button"
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "src/redux/Login/Actions"
import { Grid, Box } from "@mui/material";
import { ToastContainer } from 'react-toastify';
import RulesModal from "src/views/modal/rulesModal";
import { VerifyValidationRegister } from "src/validation/schemas/verifyValidationRegister"
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";
import { getToastConfig } from "src/utils";

const RegisterForm = ({ setMode, t }) => {
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const onUserRegister = (values, action, callBack) => {
        dispatch(LoginActions.register({ values, action, callBack }))
    }
    const showRulesModal = useSelector((state) => state.Login.showRulesModal);
    const direction = useSelector((state) => state.App.direction);
    
    const showRulesModalHandler = (visible) => {
        dispatch(LoginActions.setShowRulesModal({ visible }))

    }
    return (
        <FormContainer>
            <Formik
                initialValues={{
                    email: "",
                    orgName: "",
                    password: "",
                    confirmPassword: "",
                    rule: false,
                    en: true
                }}
                onSubmit={(values, action) => {
                    let callBack = () => {
                        setMode("login")
                    };
                    if (values.rule) {
                        onUserRegister(values, action, callBack);
                    }
                    else {
                        toast.warn(t("YourRegistrationIsOnlyPossibleIfYouAcceptTheRulesAndRegulations"),
                            getToastConfig(direction));
                    }
                }}
                validationSchema={VerifyValidationRegister}
            >
                {(formik) => (
                    <form className="" onSubmit={formik.handleSubmit}>
                        <Grid container>
                            <Grid p={1} item xs={12} className="form_title">
                                {t("register")}
                            </Grid>
                            <Grid p={1} item xs={12} display="flex" justifyContent="start">
                                <Box>{t("Alraedy a member?")}</Box><Box px={1} color="#1F499F" className="clickable" onClick={() => { setMode("login") }}>{t("login")}</Box>
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Box >
                                    <Box className='form-label'>{t("email")}</Box>
                                    <FormInput
                                        type="text"
                                        className={
                                            "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                        }
                                        value={formik.values.email}
                                        id="email"
                                        name="email"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={t("email")}
                                    />
                            
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Box>
                                    <Box className='form-label'>{t("organization")}</Box>
                                    <FormInput
                                        type="text"
                                        className={
                                            "login-form-control" + (formik.errors.orgName && formik.touched.orgName ? " warning-border" : "")
                                        }
                                        value={formik.values.orgName}
                                        id="orgName"
                                        name="orgName"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={t("organization")}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} p={1}>
                                <Box >
                                    <Box className='form-label'>{t("password")}</Box>
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.password && formik.touched.password ? " warning-border" : "")
                                        }
                                        value={formik.values.password}
                                        id="password"
                                        name="password"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={t("Enter your password")}
                                    />
                            
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Box >
                                    <Box className='form-label'>{t("repeat new password")}</Box>
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.confirmPassword && formik.touched.confirmPassword ? " warning-border" : "")
                                        }
                                        value={formik.values.confirmPassword}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={t("Enter your password again")}
                                    />
                            
                                </Box>
                            </Grid>
                            <Grid item sm={12} px={1} pt={3} display="flex" justifyContent="start">
                                <Box color="#CCCCCC">
                                    <input
                                        type="checkbox"
                                        id="rule"
                                        name="rule"
                                        onChange={(e) => {
                                            FormController.onChangeCheckbox(e, formik, null);
                                            setIsChecked(e.target.checked);
                                        }}
                                        checked={formik.values.rule}
                                    />
                                </Box>
                                <Box px={1} onClick={() => { setMode("register") }}>
                                    <span className="clickable" style={{ color: "#1F499F" }} onClick={() => showRulesModalHandler(true)}>{t("readAndAcceptedTheTermsAndConditions")}</span> 
                                </Box>
                            </Grid>
                            <Grid>
                                <input type="checkbox" value="true" name="en" id="en" style={{display: 'none'}} />
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Button
                                    onClick={() => formik.handleSubmit()}
                                    text={t("register")}
                                    disabled={!isChecked}
                                >
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </form>
                )}
            </Formik>
            <ToastContainer rtl />
            <RulesModal
                title={t("TermsAndConditions")}
                open={showRulesModal}
                onClose={showRulesModalHandler}
            />
        </FormContainer>
    )
}
export default withTranslation()(RegisterForm); 