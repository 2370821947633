import types from './Types';
import DelegateAction from '../ActionDelegator';

const LoginActions = {
  login: DelegateAction(types.LOGIN),
  logOut: DelegateAction(types.LOGOUT),
  forgetPasswordModal : DelegateAction(types.CHANGE_PASSWORD_MODAL),
  nextConfirmationStep: DelegateAction(types.NEXT_CONFIRMATION_STEP),
  sendPasswordToEmail : DelegateAction(types.SEND_PASSWORD_TO_EMAIL),
  showSuccessfulEmailAlertModal : DelegateAction(types.SHOW_SECCESSFUL_EMAIL_ALERT),
  changeAccountUserModal : DelegateAction (types.CHANGE_USER_ACCOUNT_MODAL),
  completeInfo : DelegateAction(types.COMPLETE_INFO),
  register : DelegateAction(types.REGISTER),
  forgetPassword : DelegateAction(types.FORGET_PASSWORD),
  resetPassword : DelegateAction(types.RESET_PASSWORD),
  setShowRulesModal : DelegateAction(types.SET_SHOW_RULES_MODAL),
  confirmEmail : DelegateAction(types.CONFIRM_EMAIL)
};

export default LoginActions;