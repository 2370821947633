import { RegisterFormContainer, SideContainer } from "./styles"
import { Grid } from "@mui/material";
import RegisterForm from "./registerForm";
import Logo from "src/assets/icons/LogoIcon1"
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Register = ({ setMode,t }) => {
    const lang = useSelector((state) => state.App.lang);
    return (
        <RegisterFormContainer lang={lang}>
            <Grid container id="register-container">
                <Grid item xs={12} md={7} p={2}>
                    <SideContainer>
                        <Grid container>
                            <Grid item xs={12} >
                                <Logo />
                            </Grid>
                            <Grid item xs={12} py={2}>
                             {t("project description")}
                            </Grid>
                        </Grid>
                    </SideContainer>
                </Grid>
                <Grid item xs={12} md={5} p={2}>
                    <RegisterForm setMode={setMode} />
                </Grid>
            </Grid>

        </RegisterFormContainer>
    )
}
export default withTranslation()(Register); 