export default {
    token: "token",
    userInfo: "userInfo",
    isLoggedin :"isLoggedin",
    firstLoggedin :"firstLoggedin",
    email:"email",
    rememberMe :"rememberMe",
    invoiceid :"invoiceid",
    paymentStep :"paymentStep",
    cuAndDuration :"cuAndDuration",
    unit :"unit"

    
  }