import types from "./Types";
import Storage from "../../storage";
import Keys from "../../storage/NameSpace";
import { hexToImage } from "src/utils/index";

let localUserInfo = Storage.get(Keys.userInfo)
  ? Storage.get(Keys.userInfo)
  : {};

const initState = {
  showUserProfileModal: false,
  showChangePasswordModal: false,
  userApiKey: "",
  userInfo: {
    ...localUserInfo,
  },
  logo: ""

};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.SHOW_USER_PROFILE_MODAL:
      return {
        ...state,
        showUserProfileModal: action.data.visible
      };
    case types.SHOW_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        showChangePasswordModal: action.data.visible
      };
    case types.GENERATE_API_KEY_SUCCESS:
      return {
        ...state,
        userApiKey: action.data
      }
    case types.GET_PROFILE_INFO_SUCCESS:
      Storage.set(Keys.userInfo, action.data.data);
      let logo = hexToImage(action.data.data.logo);
      return {
        ...state,
        userInfo: action.data.data,
        logo: logo
      }
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        showChangePasswordModal: false
      }
    case types.UPDATE_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        showUserProfileModal: false
      }
    default:
      return state;
  }
}