import types from './Types';
import DelegateAction from '../ActionDelegator';

const UserActions = {
  generateApiKey: DelegateAction(types.GENERATE_API_KEY),
  getProfileInfo : DelegateAction(types.GET_PROFILE_INFO),
  updateProfileInfo : DelegateAction(types.UPDATE_PROFILE_INFO),
  changePassword : DelegateAction(types.CHANGE_PASSWORD),
  getApiKeyList : DelegateAction(types.API_KEY_lIST),
  showUserProfileModal : DelegateAction(types.SHOW_USER_PROFILE_MODAL),
  showChangePasswordModal : DelegateAction(types.SHOW_CHANGE_PASSWORD_MODAL),
};

export default UserActions;