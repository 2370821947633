import types from './Types';
import DelegateAction from '../ActionDelegator';

const DashboardActions = {
  getStatisticsRequest: DelegateAction(types.GET_STATATISTICS_REQUEST),
  getUserEndpoints: DelegateAction(types.GET_USER_ENDPOINTS),
  sendStatisticsEndpoint: DelegateAction(types.SEND_STATISTICS_ENDPOINT),
  getStatisticsCu: DelegateAction(types.GET_STATATISTICS_CU),
  getStatisticsUsedEndpoint: DelegateAction(types.GET_STATATISTICS_USED_ENDPOINTS),
  sendStatisticsWaas: DelegateAction(types.SEND_STATATISTICS_WAAS),

};

export default DashboardActions;