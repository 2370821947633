import styled from 'styled-components';
import  Col from "../../../kit/Column";

export const LoginRegisterFormWrapper = styled(Col)`
    height :100vh;
    background-repeat:no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center;
    background-image :  url(${(props) => props.src});
    position:relative;
    .form_title{
        font-family: ${(props) => (props.theme.fontBold)};
        font-size: ${(props) => props.theme.fontSizeLg};
    }
`;


