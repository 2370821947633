export default {

    API_KEY_lIST: 'API_KEY_lIST',
    API_KEY_lIST_SUCCESS: 'API_KEY_lIST_SUCCESS',
    API_KEY_lIST_FAIL: 'API_KEY_lIST_FAIL',
    API_KEY_lIST_EXCEPTION: 'API_KEY_lIST_EXCEPTION',
  
    GENERATE_API_KEY: 'GENERATE_API_KEY',
    GENERATE_API_KEY_SUCCESS: 'GENERATE_API_KEY_SUCCESS',
    GENERATE_API_KEY_FAIL: 'GENERATE_API_KEY_FAIL',
    GENERATE_API_KEY_EXCEPTION: 'GENERATE_API_KEY_EXCEPTION',
  
    GET_PROFILE_INFO: 'GET_PROFILE_INFO',
    GET_PROFILE_INFO_SUCCESS: 'GET_PROFILE_INFO_SUCCESS',
    GET_PROFILE_INFO_FAIL: 'GET_PROFILE_INFO_FAIL',
    GET_PROFILE_INFO_EXCEPTION: 'GET_PROFILE_INFO_EXCEPTION',
  
    UPDATE_PROFILE_INFO: 'UPDATE_PROFILE_INFO',
    UPDATE_PROFILE_INFO_SUCCESS: 'UPDATE_PROFILE_INFO_SUCCESS',
    UPDATE_PROFILE_INFO_FAIL: 'UPDATE_PROFILE_INFO_FAIL',
    UPDATE_PROFILE_INFO_EXCEPTION: 'UPDATE_PROFILE_INFO_EXCEPTION',
  
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',
    CHANGE_PASSWORD_EXCEPTION: 'CHANGE_PASSWORD_EXCEPTION',

    SHOW_USER_PROFILE_MODAL: 'SHOW_USER_PROFILE_MODAL',
    SHOW_CHANGE_PASSWORD_MODAL:'SHOW_CHANGE_PASSWORD_MODAL'

   
  };