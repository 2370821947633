import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill="#AAA"
        d="M8.016 5.668c1.024 0 2.05-.001 3.074.003.082 0 .188.023.241.077.993 1.02 1.995 2.034 2.842 3.185.834 1.135 1.463 2.364 1.698 3.767.162.96.215 1.924-.104 2.867-.407 1.207-1.26 2.011-2.371 2.572-.96.484-1.989.734-3.046.88a17.211 17.211 0 01-4.05.075c-1.367-.134-2.7-.409-3.918-1.082-1.205-.666-2.04-1.63-2.292-3.015-.192-1.06-.06-2.106.234-3.133.358-1.245.993-2.35 1.823-3.331.806-.954 1.672-1.858 2.518-2.777.054-.058.17-.084.256-.084 1.032-.006 2.063-.004 3.094-.004zM7.41 8.722c-.13.055-.263.104-.39.166-.727.357-1.122 1.044-1.066 1.854.05.737.486 1.247 1.29 1.508.104.034.211.06.317.086.312.079.63.139.934.238.396.128.524.53.307.889-.188.308-.622.465-1.043.376-.385-.082-.606-.33-.652-.73-.038-.33-.302-.563-.615-.544-.317.02-.543.275-.542.612.003.716.483 1.39 1.22 1.678.187.072.269.15.258.364-.017.347.237.6.563.605.336.006.593-.248.587-.604-.004-.188.019-.32.239-.354.09-.014.178-.072.26-.122.693-.416 1.044-1.021.966-1.836-.076-.797-.56-1.256-1.299-1.48-.129-.04-.26-.07-.392-.103-.28-.071-.566-.126-.84-.216-.383-.125-.517-.472-.343-.833.156-.325.595-.52 1.01-.448.426.075.675.33.715.737.033.329.298.57.607.553a.58.58 0 00.55-.606 1.707 1.707 0 00-.684-1.369c-.23-.179-.504-.301-.778-.461-.054-.594-.215-.83-.588-.83-.374 0-.535.237-.59.87zM7.99 4.508c-.896 0-1.79-.003-2.686.004-.136 0-.207-.046-.27-.162-.559-1.043-1.125-2.082-1.687-3.124-.239-.44-.091-.793.383-.945 1.194-.383 2.397-.34 3.604-.076.717.156 1.43.328 2.15.462a3.833 3.833 0 002.394-.294c.3-.142.557-.1.742.104.178.197.195.452.042.735-.57 1.052-1.142 2.103-1.708 3.157-.057.107-.122.142-.24.142-.908-.005-1.817-.003-2.725-.003z"
      ></path>
    </svg>
  );
}

export default Icon;
