import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        fill="#6A707E"
        d="M0 .898L.086.696A1.118 1.118 0 011.79.224c.095.071.18.158.264.242 3.082 3.08 6.162 6.16 9.241 9.241.063.063.114.136.18.217.096-.091.161-.15.223-.213C14.8 6.61 17.903 3.51 21.003.406c.326-.327.696-.486 1.156-.366.776.2 1.094 1.111.614 1.754-.071.096-.158.18-.242.264l-9.225 9.226c-.064.063-.137.117-.227.194.08.085.138.148.198.208 3.096 3.097 6.191 6.194 9.29 9.288.371.37.54.783.357 1.299-.14.393-.453.592-.822.727h-.45c-.337-.108-.584-.34-.83-.585-3.04-3.046-6.084-6.089-9.128-9.133-.062-.062-.126-.12-.223-.212-.07.088-.118.165-.18.228-3.038 3.039-6.078 6.076-9.113 9.118-.245.245-.492.476-.83.584h-.45c-.45-.149-.755-.442-.898-.898v-.45c.11-.337.34-.585.585-.83 3.046-3.04 6.09-6.085 9.133-9.129.062-.062.12-.126.214-.223-.093-.075-.176-.128-.245-.197C6.647 8.236 3.61 5.198.57 2.162c-.24-.24-.463-.484-.569-.814V.898z"
      ></path>
    </svg>
  );
}

export default Icon;
