export default {

  GET_STATATISTICS_REQUEST: 'GET_STATATISTICS_REQUEST',
  GET_STATATISTICS_REQUEST_SUCCESS: 'GET_STATATISTICS_REQUEST_SUCCESS',
  GET_STATATISTICS_REQUEST_FAIL: 'GET_STATATISTICS_REQUEST_FAIL',
  GET_STATATISTICS_REQUEST_EXCEPTION: 'GET_STATATISTICS_REQUEST_EXCEPTION',

  GET_USER_ENDPOINTS: 'GET_USER_ENDPOINTS',
  GET_USER_ENDPOINTS_SUCCESS: 'GET_USER_ENDPOINTS_SUCCESS',
  GET_USER_ENDPOINTS_FAIL: 'GET_USER_ENDPOINTS_FAIL',
  GET_USER_ENDPOINTS_EXCEPTION: 'GET_USER_ENDPOINTS_EXCEPTION',

  SEND_STATISTICS_ENDPOINT: 'SEND_STATISTICS_ENDPOINT',
  SEND_STATISTICS_ENDPOINT_SUCCESS: 'SEND_STATISTICS_ENDPOINT_SUCCESS',
  SEND_STATISTICS_ENDPOINT_FAIL: 'SEND_STATISTICS_ENDPOINT_FAIL',
  SEND_STATISTICS_ENDPOINT_EXCEPTION: 'SEND_STATISTICS_ENDPOINT_EXCEPTION',

  GET_STATATISTICS_CU: 'GET_STATATISTICS_CU',
  GET_STATATISTICS_CU_SUCCESS: 'GET_STATATISTICS_CU_SUCCESS',
  GET_STATATISTICS_CU_FAIL: 'GET_STATATISTICS_CU_FAIL',
  GET_STATATISTICS_CU_EXCEPTION: 'GET_STATATISTICS_CU_EXCEPTION',

  GET_STATATISTICS_USED_ENDPOINTS: 'GET_STATATISTICS_USED_ENDPOINTS',
  GET_STATATISTICS_USED_ENDPOINTS_SUCCESS: 'GET_STATATISTICS_USED_ENDPOINTS_SUCCESS',
  GET_STATATISTICS_USED_ENDPOINTS_FAIL: 'GET_STATATISTICS_USED_ENDPOINTS_FAIL',
  GET_STATATISTICS_USED_ENDPOINTS_EXCEPTION: 'GET_STATATISTICS_USED_ENDPOINTS_EXCEPTION',
 
  SEND_STATATISTICS_WAAS: 'SEND_STATATISTICS_WAAS',
  SEND_STATATISTICS_WAAS_SUCCESS: 'SEND_STATATISTICS_WAAS_SUCCESS',
  SEND_STATATISTICS_WAAS_FAIL: 'SEND_STATATISTICS_WAAS_FAIL',
  SEND_STATATISTICS_WAAS_EXCEPTION: 'SEND_STATATISTICS_WAAS_EXCEPTION',
 
};