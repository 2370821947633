import React, { useRef, useState } from "react";
import { Modal, Box, Grid } from "@mui/material";
import ConfirmationIcon from "src/assets/icons/ConfirmationIcon"
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button";
import CopyIcon from "src/assets/icons/CopyIcon";
import TickIcon from "src/assets/icons/TickIcon";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { getToastConfig } from "src/utils";


const SendEmailModal = ({
    open,
    apiKey,
    onClose,
}) => {
    const direction = useSelector((state) => state.App.direction);
    const { t } = useTranslation();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
        direction : direction
    };
    const inputRef = useRef(null);
    const [isCopied , setIsCopied] = useState(false);

    const handleCopy = async () => {
        const input = inputRef.current;
        if (input) {
            await navigator.clipboard.writeText(input.value);
            setIsCopied(true);
            toast.success(t("CopySuccessfully"), getToastConfig(direction));
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => { onClose(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>
                <Grid container>
                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                        <ConfirmationIcon />
                    </Grid>
                    <Grid item xs={12} p={1} container>
                        <Grid item xs={2}  onClick={handleCopy} className="clickable" display="flex" alignItems="center">
                            {isCopied === false ? <CopyIcon /> : <TickIcon /> }
                        </Grid>
                        <Grid item xs={10}  >
                            <Box >
                                <FormInput
                                    ref={inputRef} 
                                    type="text"
                                    direction="ltr"
                                    textAlign="center"
                                    value={apiKey}
                                    id="api_key"
                                    name="api_key"
                                    disabled
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} p={1}>
                       {t("Due_to_the_lack_of_storage_in_the_system_if_it_is_lost_a_new_api_key_will_be_created_for_you")}
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <Button
                            onConfirm={() => { onClose(false) }}
                            text={t("receive")}
                        >
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    )
}
export default SendEmailModal;