export default {
    SET_SHOW_WALLET_DETAIL_MODAL: 'SET_SHOW_WALLET_DETAIL_MODAL',

    GET_WALLETS_LIST: 'GET_WALLETS_LIST',
    GET_WALLETS_LIST_SUCCESS: 'GET_WALLETS_LIST_SUCCESS',
    GET_WALLETS_LIST_FAIL: 'GET_WALLETS_LIST_FAIL',
    GET_WALLETS_LIST_EXCEPTION: 'GET_WALLETS_LIST_EXCEPTION',
  
    ADD_WALLET: 'ADD_WALLET',
    ADD_WALLET_SUCCESS: 'ADD_WALLET_SUCCESS',
    ADD_WALLET_FAIL: 'ADD_WALLET_FAIL',
    ADD_WALLET_EXCEPTION: 'ADD_WALLET_EXCEPTION',
  
    GET_WALLET_BY_ID: 'GET_WALLET_BY_ID',
    GET_WALLET_BY_ID_SUCCESS: 'GET_WALLET_BY_ID_SUCCESS',
    GET_WALLET_BY_ID_FAIL: 'GET_WALLET_BY_ID_FAIL',
    GET_WALLET_BY_ID_EXCEPTION: 'GET_WALLET_BY_ID_EXCEPTION',

    SET_SHOW_GENERATE_API_KEY_MODAL: 'SET_SHOW_GENERATE_API_KEY_MODAL',

    GET_PARENT_WALLETS_LIST: 'GET_PARENT_WALLETS_LIST',
    GET_PARENT_WALLETS_LIST_SUCCESS: 'GET_PARENT_WALLETS_LIST_SUCCESS',
    GET_PARENT_WALLETS_LIST_FAIL: 'GET_PARENT_WALLETS_LIST_FAIL',
    GET_PARENT_WALLETS_LIST_EXCEPTION: 'GET_PARENT_WALLETS_LIST_EXCEPTION',

   
  };