import React from "react";
import paymentCrtl from "../payment.controller"
import Button from "src/kit/Button";
import { CardContainer, TableWrapper } from "src/shared/generalStyles";
import { PaymentContainer } from '../styles'
import {
    Grid, Box,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    Table
} from "@mui/material";
import { NumberSeprator } from "src/utils";
import { useTranslation } from 'react-i18next';

const PrePayment = () => {
    const { handlePayment ,cuAndDuration ,handlePrevStep ,unit} = paymentCrtl();
    const { t } = useTranslation();
    return (

        <CardContainer>
            <PaymentContainer>
                <Grid container display="flex" justifyContent="start">
                    <Box className="text_bold">{t("prepayment")}</Box>
                </Grid>


                <TableWrapper>
                    <TableContainer >
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t("price")}</TableCell>
                                    <TableCell align="center">{t("computationUnit")}</TableCell>
                                    <TableCell align="center">{t("day")}</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow >
                                    <TableCell align="center">{NumberSeprator(unit)} {t("rial")}</TableCell>
                                    <TableCell align="center">{NumberSeprator(cuAndDuration?.computationUnits)}</TableCell>
                                    <TableCell align="center"> {NumberSeprator(cuAndDuration?.duration)}</TableCell>

                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableWrapper>


                <Grid container display="flex" justifyContent="end" id="payment-btn-container">
               
                    <Grid item xs={3} p={1} md={3} >
                        <Button
                            text={t("return")}
                            onConfirm={() => handlePrevStep()}
                        />
                    </Grid>
                    <Grid item xs={3} p={1} md={3} >
                        <Button
                            text={t("pay")}
                            onConfirm={() => handlePayment()}
                        />
                    </Grid>

                </Grid>

            </PaymentContainer>

        </CardContainer>

    )
}

export default PrePayment