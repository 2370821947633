import React from "react";
import { PieChartContainer, } from "../styles"
import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import { withTranslation } from "react-i18next";

const Chart = ({t}) => {
    const DashboardState = useSelector((state) => state.Dashboard);
    const theme = useSelector((state) => state.App.theme);
    const direction = useSelector((state) => state.App.direction);

    const { statisticsCu } = DashboardState;
    return (
        <ChartContainer height="447px">
            <Grid container  display="flex" justifyContent="center" pb={5}>
                <TitleContainer paddingtop="20px">{t("Cost and Usage overview")}</TitleContainer>
            </Grid>
            <Grid container display="flex" justifyContent="center" alignItems="center" py={5}>
                <Grid item md={6} display="flex" justifyContent="center" alignItems="center">
                    <PieChartContainer direction={direction}>
                        <Box className="box">
                            <Box color={theme.primary} className="box-label">{t("unit")}</Box><Box className="orange-text">{statisticsCu?.usedCu}</Box>
                        </Box>
                        <Box className="box">
                            <Box color="#ccc" className="box-label">{t("unit")}</Box><Box className="gray-text"> {statisticsCu?.totalCu}</Box>
                        </Box>
                    </PieChartContainer>
                </Grid>
                <Grid item md={6} display="flex" justifyContent="center" alignItems="center">
                    <PieChartContainer direction={direction}>
                        <Box className="box">
                            <Box color={theme.primary} className="box-label">{t("day")}</Box> <Box className="orange-text"> {statisticsCu?.passedDays}</Box>
                        </Box>
                        <Box className="box">
                            <Box color="#ccc" className="box-label">{t("day")}</Box> <Box className="gray-text">{statisticsCu?.totalDays}</Box>
                        </Box>
                    </PieChartContainer>
                </Grid>
            </Grid>
        </ChartContainer>
    )
}
export default withTranslation()(Chart);  