import React from "react";
import { Grid, Box } from "@mui/material";
import { CardContainer } from "../styles"
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import LeftArrowIcon from "src/assets/icons/LeftArrowIcon";
import RightArrowIcon from "src/assets/icons/RightArrowIcon"
import { Link } from "react-router-dom";
import subSystemMenuCrtl from "../dashboard.controllers";
import { withTranslation } from "react-i18next";

const RecentEndPoint = ({ t }) => {
    const { statisticsUsedEndpoint, direction } = subSystemMenuCrtl();

    return (
        <ChartContainer>
            <Grid container>
                <Grid item xs={12} display="flex" justifyContent="space-between" p={2}>
                    <TitleContainer paddingtop="0px">{t("Recent active endpoints")}</TitleContainer>
                    <Link to="./endpoint">
                        <Box fontSize={17} color="#1F499F" display="flex" alignItems="center">{t("View all endpoints")}
                            <Box display="flex" p={1}>
                                {direction === "ltr" ? <RightArrowIcon /> : <LeftArrowIcon />}
                            </Box>
                        </Box>
                    </Link>
                </Grid>
                {statisticsUsedEndpoint.length > 0 && statisticsUsedEndpoint.map((each, index) => {
                    return (
                        <Grid item xs={12} md={6} lg={3} key={each.id} p={2}>
                            <CardContainer>
                                <img src={`data:image/jpeg;base64,${each.logo}`} />
                                <Box className="box-title">{each.name}</Box>
                                <Box>
                                    <Box my={1}> {t("successful requests")} ({t("24 hours")}) : {each.lastDaySuccessCount}</Box>
                                    <Box my={1}>{t("failed requests")} ({t("24 hours")}) : {each.lastDayFailedCount}</Box>
                                    <Box my={1}> {t("successful requests")} ({t("1 week")}) : {each.lastWeekSuccessCount}</Box>
                                    <Box my={1}>{t("failed requests")} ({t("1 week")}) : {each.lastWeekFailedCount}</Box>
                                    <Box my={1}>{t("successful requests")} ({t("1 month")}) : {each.lastMonthSuccessCount}</Box>
                                    <Box my={1}> {t("failed requests")} ({t("1 month")}) : {each.lastMonthFailedCount}</Box>
                                </Box>

                            </CardContainer>
                        </Grid>
                    )
                })}
            </Grid>
        </ChartContainer>
    )
}
export default withTranslation()(RecentEndPoint);   