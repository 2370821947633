import types from './Types';
import DelegateAction from '../ActionDelegator';

const PaymentActions = {
  addComputationUnit: DelegateAction(types.ADD_COMPUTATION_UNIT),
  getCu: DelegateAction(types.GET_CU),
  getPriceList: DelegateAction(types.GET_PRICE_LIST),
  getPaymentList : DelegateAction(types.GET_PAYMENT_LIST),
  nextPaymentStep : DelegateAction(types.NEXT_PAYMENT_STEP),
  prevPaymentStep : DelegateAction(types.PREV_PAYMENT_STEP),
  makeInvoice : DelegateAction(types.MAKE_INVOICE),
  resetPaymentData : DelegateAction(types.RESET_PAYMENT_DATA)
};

export default PaymentActions;