import types from "./Types";
import { prepareSelectData } from "src/utils";

const initState = {
  statisticsData: null,
  endpointsList: [],
  endpointsListOptions: [],
  statisticsEndpointList: [],
  statisticsEndpointListLoading: true,
  statisticsCu: null,
  statisticsUsedEndpoint: [],
  statisticsWaas : []
};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.GET_STATATISTICS_REQUEST_SUCCESS:
      return {
        ...state,
        statisticsData: action.data.data
      };
    case types.GET_USER_ENDPOINTS_SUCCESS:
      return {
        ...state,
        endpointsList: action.data.data,
        endpointsListOptions: prepareSelectData("endpoint", action.data.data)
      };
    case types.SEND_STATISTICS_ENDPOINT_SUCCESS:
      return {
        ...state,
        statisticsEndpointList: action.data,
        statisticsEndpointListLoading: false
      };
    case types.GET_STATATISTICS_CU_SUCCESS:
      return {
        ...state,
        statisticsCu: action.data.data,
      };
    case types.GET_STATATISTICS_USED_ENDPOINTS_SUCCESS:
      return {
        ...state,
        statisticsUsedEndpoint: action.data.data,
      };
    case types.SEND_STATATISTICS_WAAS_SUCCESS:
      return {
        ...state,
        statisticsWaas: action.data,
      };
    default:
      return state;
  }
}