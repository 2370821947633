export const rules = [
    {
        id: "1", text: `
   	    Acceptance of Terms: By accessing or using the Services provided by Polareum, you agree to abide by these Terms and any additional terms and conditions that may apply to specific sections of the Services. Your use of the Services constitutes your acceptance of these Terms and any modifications made to them. If you do not agree to these Terms, you may not access or use the Services.
    `},
    {
        id: "2", text: `
    	Access to Services: Polareum grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your personal or internal business purposes. This license is subject to your compliance with these Terms. You may not sublicense, transfer, or assign this license without Polareum's prior written consent. Polareum reserves the right to modify, suspend, or discontinue the Services or any part thereof at any time without prior notice.
    `},
    {
        id: "3", text: `
    	User Conduct: You agree to use the Services only for lawful purposes and in accordance with these Terms. When using the Services, you agree to comply with all applicable laws, regulations, and rules. You shall not engage in any activity that interferes with or disrupts the Services, servers, or networks connected to the Services. Prohibited activities include but are not limited to: (a) attempting to gain unauthorized access to the Services or other accounts, (b) using the Services in any manner that could damage, disable, overburden, or impair the Services, (c) impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity, and (d) transmitting any material that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable. Polareum reserves the right to investigate and prosecute violations of any of the above, to the fullest extent of the law.
    `},
    {
        id: "4", text: `
        Intellectual Property: All content, trademarks, logos, and intellectual property displayed on the Polareum website are the property of Polareum or its licensors. You may not use, modify, reproduce, distribute, or exploit any of the content without prior written consent from Polareum. You acknowledge and agree that the Services and any associated software used in connection with the Services contain proprietary and confidential information that is protected by applicable intellectual property and other laws.
    `},
    {
        id: "5", text: `
    	Privacy: Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and disclose your information when you use our Services.
    `},
    {
        id: "6", text: `
    	Disclaimer of Warranties: The Services are provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. Polareum disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the Services will be uninterrupted, timely, secure, or error-free, or that any defects will be corrected.
    `},
    {
        id: "7", text: `
    	Limitation of Liability: In no event shall Polareum be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the Services or inability to access or use the Services. This limitation of liability applies to all claims arising out of or in any way related to these Terms, whether based on warranty, contract, tort, strict liability, or any other legal theory.
    `},
    {
        id: "8", text: `
        Refunds: Unless otherwise expressly agreed, all fees are non-refundable. You may be entitled to a refund if we provide you with a service that does not match the product description of the service that you purchased. If that occurs, and you wish to initiate the refund process, please contact us.
    `},
    {
        id: "9" ,text : `
        Indemnification: You agree to indemnify and hold Polareum harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms or your violation of any law or the rights of a third party.
    `},
    {
        id: "10" ,text : `
        Changes to Terms: Polareum reserves the right to update or modify these Terms at any time without prior notice. Your continued use of the Services after any such changes indicates your acceptance of the modified Terms. It is your responsibility to review these Terms periodically for changes.
    `},
    {
        id: "11" ,text : `
        Termination: Polareum may terminate or suspend your access to the Services at any time, with or without cause, and without prior notice. Upon termination, your right to use the Services will immediately cease. All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
    `},
    {
        id: "12" ,text : `
        Governing Law: These Terms shall be governed by and construed in accordance with the laws of Islamic Republic of Iran, without regard to its conflict of law provisions.
    `},
    {
        id: "13" ,text : `
        Dispute Resolution: Any dispute arising out of or relating to these Terms or the Services shall initially be resolved through negotiation between the parties, and in the event of failure to reach a resolution, shall be settled through the competent judicial authorities in Tehran, where the legal domicile of Polareum is located.
    `},
    {
        id: "14" ,text : `
        Contact Us: If you have any questions about these Terms, please contact us at [Company Contact Information].
        By using the Polareum Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.
    `}


]