
import Row from "src/kit/Row";
import moment from "moment-jalaali";
import { useTranslation } from 'react-i18next';

const PaymentHistoryTableConfig = () => {
  const { t } = useTranslation();
  return {
    showHeader: true,

    row: [
      {
        headerTitle:  t('rowIndex'),
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle:  t('price'),
        displayTitle: "price",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.price !== "" ? props.row.price : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('computationUnit'),
        displayTitle: "totalComputationUnit",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.totalComputationUnit !== "" ? props.row.totalComputationUnit : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('startDate'),
        displayTitle: "startDate",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              { props.row.startDate !== null  ? moment(props.row.startDate).format("YYYY-MM-DD") : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t('finishedDate'),
        displayTitle: "finishedDate",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              { props.row.finishedDate !== null ? moment(props.row.finishedDate).format("YYYY-MM-DD") : "-"}
            </Row>
          );
        },
      },
    ],
  };
};
export default PaymentHistoryTableConfig;