import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentActions from "src/redux/Payment/Actions";
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const paymentState = useSelector((state) => state.Payment);
    const { cuAndDuration, priceList ,unit } = paymentState;
    const [value, setValue] = React.useState(0);
    const [sliderData, setSliderData] = React.useState({
        min: null,
        max: null,
        steps: []
    });

    const valueLabelFormat = (value) => {
        if (priceList.length > 0) {
            return priceList[value].toLocaleString()+" "+t("rial");
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        handlegetCu(sliderData?.steps[newValue] );
    };

    const handlegetCu = (value) => {
        dispatch(PaymentActions.getCu({ value: value }));
    };

    const handlePayment = () => {
        let callBack = (url) => {
            //  history.push(url);
          
             handleNextStep(2);
        };
        
         dispatch(PaymentActions.makeInvoice({ computationUnitCount: cuAndDuration?.computationUnits, price :unit, callBack }));
    };

    const handleNextStep = (step) => {
        dispatch(PaymentActions.nextPaymentStep(step));
    };
    const handlePrevStep = (step) => {
        dispatch(PaymentActions.prevPaymentStep(step));
    };
  return {
    handleChange,
    handlePayment,
    valueLabelFormat,
    priceList,
    cuAndDuration,
    value,
    handleNextStep,
    handlePrevStep,
    sliderData,
    setSliderData,
    handlegetCu,
    unit
  }
}
